import {BaseAPI} from "@/api/BaseAPI";
import {APIHelper} from "@/service/APIHelper";
import {CachePath} from "@/service/cache/CacheConfiguration";
import {Sentry} from "@/service/Sentry";
import {merge} from "@/utils/object";

const BuyerAPI = {

    API: BaseAPI,
    DOMAIN: 'buyers',
    DOMAIN_DELIVERY_ADDRESS: 'delivery-addresses',
    DOMAIN_WHOLESALE_DISCOUNTS: 'wholesale-discounts',
    LANG_DELIVERY_ADDRESS: 'addresses',
    LANG_WHOLESALE_DISCOUNTS: 'wholesaleDiscounts',

    /**
     * @param params {{
     *     page: int
     *     itemsPerPage: int
     *     filter: {Object}
     *     sort: {
     *         key: 'ASC'|'DESC'
     *     }
     * }}
     * @returns {Promise<unknown>}
     */
    getAll(...params) {
        return this.API.get(this.DOMAIN, undefined, merge(...params));
    },

    /**
     * @deprecated Too heavy API usage, use pagination
     */
    getAllPages(...params) {
        return APIHelper.getAllPages(this.DOMAIN, undefined, merge(...params));
    },

    get(id) {
        return APIHelper.getWithCache({
            collection: CachePath.buyers,
            key: id,
            requestParams: [[this.DOMAIN, id]]
        });
    },

    getByIco(ico) {
        return this.API.get([this.DOMAIN, 'by-ico', ico]);
    },

    create(data) {
        return this.API.post(
            this.DOMAIN,
            data
        );
    },

    undoCreateFn(id, resolve, reject) {
        return () => {
            return this.API.delete([this.DOMAIN, id])
                .then(resolve)
                .catch((error) => {
                    Sentry.captureException(error);
                    reject();
                });
        };
    },

    update(id, data) {
        return this.API.patch(
            [this.DOMAIN, id],
            data
        );
    },

    delete(id) {
        return this.API.delete(
            [this.DOMAIN, id],
            [this.DOMAIN, 'delete']
        );
    },



    // Delivery addresses

    getAllAddresses(buyerId) {
        return this.API.get(
            [this.DOMAIN, buyerId, this.DOMAIN_DELIVERY_ADDRESS],
            [this.DOMAIN, this.LANG_DELIVERY_ADDRESS]
        );
    },

    getAddress (buyerId, addressId) {
        return this.API.get(
            [this.DOMAIN, buyerId, this.DOMAIN_DELIVERY_ADDRESS, addressId],
            [this.DOMAIN, this.LANG_DELIVERY_ADDRESS]
        );
    },

    createAddress(buyerId, data) {
        return this.API.post(
            [this.DOMAIN, buyerId, this.DOMAIN_DELIVERY_ADDRESS],
            data,
            [this.DOMAIN, this.LANG_DELIVERY_ADDRESS]
        );
    },

    updateAddress(buyerId, addressId, data) {
        return this.API.patch(
            [this.DOMAIN, buyerId, this.DOMAIN_DELIVERY_ADDRESS, addressId],
            data,
            [this.DOMAIN, this.LANG_DELIVERY_ADDRESS]
        );
    },

    deleteAddress(buyerId, addressId) {
        return this.API.delete(
            [this.DOMAIN, buyerId, this.DOMAIN_DELIVERY_ADDRESS, addressId],
            [this.DOMAIN, this.LANG_DELIVERY_ADDRESS]
        );
    },

    // wholesale discounts

    getAllDiscounts(buyerId, ...params) {
        return this.API.get(
            [this.DOMAIN, buyerId, this.DOMAIN_WHOLESALE_DISCOUNTS],
            undefined,
            merge(...params)
        );
    },

    getAllDiscountsAllPages(buyerId, params) {
        return APIHelper.getAllPages(
            [this.DOMAIN, buyerId, this.DOMAIN_WHOLESALE_DISCOUNTS],
            undefined,
            params
        );
    },

    getDiscount(buyerId, discountId) {
        return this.API.get(
            [this.DOMAIN, buyerId, this.DOMAIN_WHOLESALE_DISCOUNTS, discountId],
            [this.DOMAIN, this.LANG_WHOLESALE_DISCOUNTS]
        );
    },

    createDiscount(buyerId, data) {
        return this.API.post(
            [this.DOMAIN, buyerId, this.DOMAIN_WHOLESALE_DISCOUNTS],
            data
        );
    },

    updateDiscount(buyerId, discountId, data) {
        return this.API.patch(
            [this.DOMAIN, buyerId, this.DOMAIN_WHOLESALE_DISCOUNTS, discountId],
            data,
            [this.DOMAIN, this.LANG_WHOLESALE_DISCOUNTS]
        );
    },

    deleteDiscount(buyerId, discountId) {
        return this.API.delete(
            [this.DOMAIN, buyerId, this.DOMAIN_WHOLESALE_DISCOUNTS, discountId],
            [this.DOMAIN, this.LANG_WHOLESALE_DISCOUNTS]
        );
    }
};

export {BuyerAPI};
