<template>
  <div>
    <TaskConflict
      v-if="conflict"
      :error="conflict"
    />
    <v-alert
      v-if="showUnprocessedItemsWarning"
      type="warning"
    >
      {{ $t('tasks.unprocessedItemsRemaining') }}
    </v-alert>
    <v-layout wrap>
      <v-flex
        md8
        xs12
      >
        <TaskSimpleStateChangeCard
          v-if="isWorker && !isChief && assignedToCurrentUser && !isClosed"
          :api="api"
          :action="TaskAction.FINISH"
          :task-info="taskInfo"
          @conflict="cnflct => conflict = cnflct"
        />
        <slot name="approveOption">
          <TaskSimpleStateChangeCard
            v-if="isWorker && isChief && isInProgress && assignedToCurrentUser"
            :api="api"
            :finish-and-approve="true"
            :task-info="taskInfo"
            @conflict="cnflct => conflict = cnflct"
          />
          <TaskSimpleStateChangeCard
            v-if="isChief && isWaitingForApproval"
            :api="api"
            :action="TaskAction.APPROVE"
            :task-info="taskInfo"
            @conflict="cnflct => conflict = cnflct"
          />
        </slot>
      </v-flex>
      <v-flex
        v-if="assignedToCurrentUser && !isWaitingForApproval && !isType(taskTypes.STOCK_TAKING) && !isClosed"
        md4
        xs12
      >
        <TaskSimpleStateChangeCard
          :api="api"
          :action="TaskAction.UNASSIGN"
          :task-info="taskInfo"
        />
      </v-flex>
      <v-flex
        v-if="isChief && (isCreated || isUnassigned || isWaitingForApproval)
          && !isType(taskTypes.LOCATION_TRANSFER)
          && (!isType(taskTypes.EXTERNAL_ORDER) || !isWaitingForApproval)"
        md4
        xs12
      >
        <TaskSimpleStateChangeCard
          :api="api"
          :action="isWaitingForApproval ? TaskAction.REJECT : TaskAction.CANCEL"
          :task-info="taskInfo"
        />
      </v-flex>
    </v-layout>
    <v-card
      v-if="showMovements"
      class="ml-1 mt-1"
    >
      <v-card-title class="text-h5">
        {{ $t('tasks.movementsOverview.title') }}
      </v-card-title>
      <v-card-text class="mt-n10">
        <div
          v-if="isType(taskTypes.SUBSTOCK_TRANSFER)"
          class="mt-8 text-h6 mb-n10"
        >
          {{ $t('tasks.movementsOverview.sourceSubstock') + ': ' }}
          {{ taskInfo.details.source_subordinate_stock.name }}
        </div>
        <StockMovementsTable
          :show-task-id="isType(taskTypes.STOCK_PICKING_SET)"
          :show-task-type="false"
          :show-sub-stock="false"
          :api-filter="sourceApiFilter"
          :reload="reloadMovements"
        />
        <div
          v-if="isType(taskTypes.SUBSTOCK_TRANSFER)"
          class="text-h6 mb-n10"
        >
          {{ $t('tasks.movementsOverview.destinationSubstock') + ': ' }}
          {{ taskInfo.details.destination_subordinate_stock.name }}
        </div>
        <StockMovementsTable
          v-if="isType(taskTypes.SUBSTOCK_TRANSFER)"
          :show-task-id="isType(taskTypes.STOCK_PICKING_SET)"
          :show-task-type="false"
          :show-sub-stock="false"
          :api-filter="destinationApiFilter"
          :reload="reloadMovements"
        />
      </v-card-text>
    </v-card>
    <StockLoadingItemsPrices
      v-if="isType(taskTypes.STOCK_LOADING) && isChief"
      :task-info="taskInfo"
      class="ml-1 mt-1"
    />
  </div>
</template>

<script>
    import TaskSimpleStateChangeCard from "@/app/tasks/components/TaskSimpleStateChangeCard.component";
    import {ACLMixin} from "@/app/mixins/ACLMixin";
    import {TaskStateMixin} from "@/app/mixins/TaskStateMixin";
    import {Roles} from "@/enum/roles";
    import StockMovementsTable from "@/app/overview/movements/components/StockMovementsTable.component";
    import {TaskTypeMixin} from "@/app/mixins/TaskTypeMixin";
    import {taskTypes} from "@/enum/task_type";
    import {CreatedOrActivatedMixin} from "@/app/mixins/CreatedOrActivatedMixin";
    import {TaskAssignMixin} from "@/app/mixins/TaskAssignMixin";
    import StockLoadingItemsPrices from "@/app/tasks/stockLoading/components/StockLoadingItemsPrices.component";
    import {APIFilterOP} from "@/service/APIFilters";
    import {TaskAction} from "@/enum/task_action";
    import TaskConflict from "@/app/tasks/components/TaskConflict.component.vue";

    export default {
        name: "TaskDefaultOverview",
        components: {TaskConflict, TaskSimpleStateChangeCard, StockMovementsTable, StockLoadingItemsPrices},
        mixins: [ACLMixin, TaskStateMixin, TaskTypeMixin, CreatedOrActivatedMixin, TaskAssignMixin],
        props: {
            api: {
                type: Object,
                default: () => ({})
            },
            taskInfo: {
                type: Object,
                default: () => ({})
            },
            items: {
                type: Array,
                default: () => []
            },
            workerRole: {
                type: String,
                default: Roles.STOREKEEPER
            }
        },
        data: () => ({
            apiFilter: [],
            reloadMovements: 0,
            taskTypes: taskTypes,
            conflict: null,
            TaskAction: TaskAction
        }),
        computed: {
            isWorker: function () {
                return this.$store.getters['oauth/hasScope'](this.workerRole);
            },
            showMovements: function () {
                return this.isAnyOfTypes([taskTypes.STOCK_PICKING, taskTypes.STOCK_PICKING_SET, taskTypes.MOVE_PRODUCTS, taskTypes.SUBSTOCK_TRANSFER]);
            },
            showUnprocessedItemsWarning: function () {
                if (!this.isAnyOfTypes([taskTypes.MOVE_PRODUCTS, taskTypes.SUBSTOCK_TRANSFER]))
                    return false;
                return this.items.some(item => item.processed_quantity < item.quantity_to_move);
            },
            subStockId: function () {
                if (this.isAnyOfTypes([taskTypes.STOCK_PICKING, taskTypes.STOCK_PICKING_SET, taskTypes.MOVE_PRODUCTS])) {
                    return this.taskInfo.details.subordinate_stock.id;
                } else {
                    return this.taskInfo.details.source_subordinate_stock.id;
                }
            },
            sourceApiFilter: function () {
                return [...this.apiFilter,
                        {
                            [APIFilterOP.EQUALS]: {
                                'substock.id': this.subStockId
                            }
                        }];
            },
            destinationApiFilter: function () {
                return [...this.apiFilter,
                        {
                            [APIFilterOP.EQUALS]: {
                                'substock.id': this.taskInfo.details.destination_subordinate_stock.id
                            }
                        }];
            }
        },
        watch: {
            'taskInfo.tab': function (tab) {
                if (tab === 2) {
                    this.reloadMovements++;
                }
            }
        },
        createdOrActivated: function (lifeCycleHook) {
            if (lifeCycleHook === this.LifeCycleHook.CREATED) {
                this.addTaskFilter();
            }
            this.conflict = null;
        },
        methods: {
            addTaskFilter: function () {
                if (this.isType(taskTypes.STOCK_PICKING_SET) && this.taskInfo.details.tasks && this.taskInfo.details.tasks.length !== 0) {
                    this.apiFilter.push({
                        [APIFilterOP.IN]: {
                            'task.id': this.taskInfo.details.tasks.map(task => task.id)
                        }
                    });
                } else if (this.taskInfo.details.id) {
                    this.apiFilter.push({
                        [APIFilterOP.EQUALS]: {
                            'task.id': this.taskInfo.details.id
                        }
                    });
                }
            },
        }
    };
</script>

<style scoped>

</style>
