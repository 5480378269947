import {ReactiveBarcodeCacheMixin} from "@/app/mixins/ReactiveBarcodeCacheMixin";
import {ReactiveLocationCacheMixin} from "@/app/mixins/ReactiveLocationCacheMixin";
import {BarcodeAPI} from "@/api/BarcodeAPI";
import {CodeType} from "@/enum/code_type";
import {CachePath} from "@/service/cache/CacheConfiguration";
import {IndexedDB} from "@/service/cache/IndexedDB";
import {EventBus} from "@/service/EventBus";
import {StockAPI} from "@/api/StockAPI";
import {APIFilterOP, APIFilters} from "@/service/APIFilters";

/**
 * Requires:
 * - this.loading
 * - this.taskInfo.details
 * - this.taskInfo.details.subordinate_stock.stock_id
 * - this.taskInfo.details.subordinate_stock.id
 * - this.API (either TaskStockPickingAPI or TaskStockPickingSetAPI)
 */
const TaskSetTargetLocationMixin = {
    mixins: [ReactiveLocationCacheMixin, ReactiveBarcodeCacheMixin],
    methods: {
        getEmptyLocation: function (locationId) {
            return StockAPI.getAllSubstockAvailableLocations(this.taskInfo.details.subordinate_stock.stock_id, this.taskInfo.details.subordinate_stock.id, {
                filter: APIFilters.makeFilter({
                    [APIFilterOP.EQUALS]: {
                        id: locationId
                    }
                })
            });
        },
        setTargetLocation: function (code) {
            BarcodeAPI.decode(code).then(response => {
                const barcodeInfo = response.data;
                if (barcodeInfo.type === CodeType.STOCK_LOCATION) {
                    return StockAPI.getLocationWOStock(barcodeInfo.object_id)
                        .then(response => {
                            if (response.data.is_mobile) {
                                // check location is allowed in substock
                                this.getEmptyLocation(barcodeInfo.object_id).then(response => {
                                    if (response.data.items.find(loc => loc.id === barcodeInfo.object_id)) {
                                        this.reallySetTargetLocation(barcodeInfo.object_id);
                                    }
                                })
                                .catch(this.snack)
                                .finally(() => {
                                    this.loading = false;
                                });
                            }
                        });
                }
            }).catch(this.snack);
        },
        reallySetTargetLocation: function (targetLocationId) {
            this.loading = true;
            this.API.setDestination(this.taskInfo.taskId, targetLocationId)
                .then(() => {
                    // eslint-disable-next-line vue/no-mutating-props
                    this.$set(this.taskInfo.details, 'target_location_id', targetLocationId);
                    EventBus.$emit('task-targetLocation-picked');
                }).catch(this.snack)
                .finally(() => {
                    this.loading = false;
                });
        },
        fetchBarcodeToCache: function (code) {
            this.cacheBarcode(BarcodeAPI.decode(code), code)
                .then(response => {
                    const data = response.data;
                    if (data.type === CodeType.STOCK_LOCATION) {
                        IndexedDB.save(CachePath.locations, data.object_id, data.object_info);
                    }
                })
                .catch(this.snack);
        }
    }
};

export {TaskSetTargetLocationMixin};
