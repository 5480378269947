<template>
  <div>
    <template v-if="order">
      <v-card class="mx-1 px-1">
        <v-container fluid>
          <div>
            <span class="text-caption">{{ $t('tasks.preparePackage.orders.order_id') + ': ' }}</span>
            <router-link :to="taskLinks[taskTypes.EXTERNAL_ORDER] + '/' + order.id">
              #{{ order.id }}
            </router-link>
          </div>
          <div v-if="order.external_order_states && order.external_order_states.length > 0">
            <span class="text-caption">{{ $t('tasks.externalOrder.state.label') }}:</span>
            <ul>
              <li
                v-for="state of order.external_order_states"
                :key="state"
              >
                {{ $t('tasks.externalOrder.state.' + state) }}
              </li>
            </ul>
          </div>
          <div>
            <span class="text-caption">{{ $t('tasks.externalOrder.subordinate_stock_id') }}:</span>
            {{ order.subordinate_stock.name }}
          </div>
          <div>
            <ExternalOrderBuyerDetail
              :order-id="order.id"
              :buyer-id="order.buyer_id"
            />
          </div>
          <ExternalOrderParametersList :order="order" />
          <div>
            <span class="text-caption">{{ $t('tasks.externalOrder.externalFields.label') }}:</span>
            <ul>
              <div v-if="order.external_order_id">
                <li>
                  <span class="text-caption">{{
                    $t('tasks.preparePackage.orders.external_order_id') + ': '
                  }}</span>
                  {{ order.external_order_id }}
                </li>
              </div>
              <div v-if="order.external_note !== null">
                <li>
                  <span class="text-caption">{{
                    $t('tasks.preparePackage.orders.external_order_note') + ': '
                  }}</span>
                  <strong>{{ order.external_note }}</strong>
                </li>
              </div>
              <div
                v-for="field of ['author_name', 'author_id', 'id']"
                :key="field"
              >
                <li v-if="order['external_' + field]">
                  <span class="text-caption">{{
                    $t('tasks.externalOrder.externalFields.' + field)
                  }}</span>:
                  {{ order['external_' + field] }}
                </li>
              </div>
              <li v-if="order.external_order_created_at">
                <span class="text-caption">{{
                  $t('tasks.externalOrder.externalFields.order_created_at')
                }}</span>:
                <DateTimeWithTooltip :date-time="order.external_order_created_at" />
              </li>
            </ul>
          </div>
          <div
            v-if="invoiceLink || eshopAttachments"
            class="mt-3"
          >
            <span
              v-show="$vuetify.breakpoint.smAndUp"
              class="text-caption"
            >
              {{ $t('tasks.preparePackage.shipments.print') }}:
            </span>
            <v-chip
              v-if="invoiceLink"
              class="py-6 mx-2 buttonGroup"
              color="muted"
              label
              outlined
            >
              <span
                class="text-uppercase mr-2"
              >
                {{ $t('tasks.preparePackage.orders.invoice') }}
              </span>
              <v-btn
                v-for="copies of 2"
                :key="copies"
                color="secondary"
                outlined
                :loading="loadingPrint"
                @click="printInvoice(copies)"
              >
                <v-icon
                  v-for="i of copies"
                  :key="copies + '-' + i"
                >
                  $invoice
                </v-icon>
                <span class="ml-2">
                  {{ $t('tasks.preparePackage.orders.printInvoiceAmount.' + copies) }}
                </span>
              </v-btn>
            </v-chip>
            <v-btn
              v-if="eshopAttachments"
              class="ml-4"
              color="secondary"
              outlined
              :loading="loadingPrint"
              @click="printAttachments"
            >
              <v-icon>
                $attachment
              </v-icon>
              <span class="ml-2">
                {{ $t('tasks.preparePackage.orders.eshopAttachments') }}
              </span>
            </v-btn>
          </div>
          <v-divider
            class="my-2"
          />
          <Notes
            :notes="order.notes"
            inline
          />
          <v-divider
            v-if="order.attachments.length > 0"
            class="my-2"
          />
          <span class="text-caption">{{ $t('tasks.attachments.name') + ':' }}</span>
          <TaskAttachments
            :attachments="order.attachments"
            :task-id="order.id"
            inline
          />
          <v-divider
            class="my-2"
          />
          <EntityHistory
            :changes="order"
          />
        </v-container>
      </v-card>
    </template>
    <template v-else>
      <v-progress-linear
        indeterminate
      />
    </template>
  </div>
</template>

<script>
    import EntityHistory from "@/app/components/EntityHistory.component";
    import {ACLMixin} from "@/app/mixins/ACLMixin";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import TaskAttachments from "@/app/tasks/components/TaskAttachments.component";
    import Notes from "@/app/tasks/components/TaskNotes.component";
    import * as Export from "@/service/Export";
    import {PrintType} from "@/enum/print_type";
    import DateTimeWithTooltip from "@/app/components/DateTimeWithTooltip.component";
    import {Env} from "@/service/Environment";
    import ExternalOrderParametersList
        from "@/app/tasks/externalOrder/components/ExternalOrderParametersList.component";
    import ExternalOrderBuyerDetail from "@/app/tasks/externalOrder/components/ExternalOrderBuyerDetail.component.vue";
    import {taskLinks, taskTypes} from "@/enum/task_type";

    export default {
        name: "OrderDetail",
        components: {
            ExternalOrderBuyerDetail,
            ExternalOrderParametersList, TaskAttachments, EntityHistory, Notes, DateTimeWithTooltip
        },
        mixins: [ACLMixin, RouteParamsMapperMixin],
        props: {
            order: {
                type: Object,
                default: () => null
            },
            shipment: {
                type: Object,
                default: () => null
            },
            firstItemPackedTrigger: {
                type: Number,
                default: 0
            }
        },
        data: () => ({
            loadingPrint: false,
            taskLinks: taskLinks,
            taskTypes: taskTypes
        }),
        computed: {
            invoiceLink: function () {
                const carrierServiceParams = this.order?.carrier_service_parameters;
                if (!carrierServiceParams) {
                    return null;
                }
                return carrierServiceParams.find(param => param.name === 'invoiceLink')?.value;
            },
            eshopAttachments: function () {
                const carrierServiceParams = this.order?.carrier_service_parameters;
                if (!carrierServiceParams) {
                    return null;
                }
                const value = carrierServiceParams.find(param => param.name === 'eshopAttachments')?.value;
                if (!value) {
                    return null;
                }
                try {
                    return JSON.parse(value);
                } catch {
                    // invalid JSON, cannot parse
                    return null;
                }
            }
        },
        watch: {
            firstItemPackedTrigger: function () {
                if (this.invoiceLink) {
                    this.printInvoice();
                }
                if (this.eshopAttachments) {
                    this.printAttachments();
                }
            }
        },
        methods: {
            printInvoice: function (amount = 1) {
                if (!Env.isElectron()) {
                    // we want to download invoice just once
                    amount = 1;
                }
                for (let i = 0; i < amount; i++) {
                    Export.print(this.invoiceLink, 'invoice_' + this.order.id, PrintType.PDF);
                }
            },
            printAttachments: function () {
                for (let i = 0; i < this.eshopAttachments.length; i++) {
                    Export.print(this.eshopAttachments[i], `attachment_${this.order.id}_${i}`, PrintType.PDF);
                }
            }
        }
    };
</script>

<style lang="sass">
.buttonGroup:hover:before
  opacity: 0 !important

.buttonGroup span
  color: var(--v-secondary-base) !important
</style>
