export default {
    title: "Dodavatelé",
    link: "Dodavatelé",

    name: "Název",
    ico: "IČO",
    dic: "DIČ",
    phone: "Telefon",
    email: "E-mail",
    website: "Web",
    billing_address: "Fakturační adresa",

    list: {
        unableToLoad: "Seznam dodavatelů nebylo možné načíst. Zkuste to prosím znovu později."
    },
    show: {
        title: "Dodavatel",
        tasks: "Příjmy dodávky a naskladnění dodavatele",
    },
    create: {
        label: "Vytvořit nového dodavatele",
        title: "Vytvořit dodavatele",
        done: "Dodavatel '{0}' vytvořen.",
        undo: {
            done: "Tvorba '{0}' úspěšně vrácena!",
            failed: "Nezdařilo se vrátit tvorbu dodavatele!"
        }
    },
    update: {
        titleSimple: "Upravit dodavatele",
        title: "Upravit {0}",
        done: "Dodavatel '{0}' upraven",
        undo: {
            done: "Úpravy '{0}' úspěšně vráceny!",
            failed: "Nezdařilo se vrátit úpravu dodavatele!"
        }
    },
    delete: {
        done: "Dodavatel '{0}' smazán.",
        failed: "Dodavatel '{0}' nelze smazat. Zkuste to prosím znovu později.",
        undo: {
            done: "Smazání '{0}' úspěšně vráceno!",
            failed: "Nezdařilo se vrátit smazání dodavatele!"
        },
        status: {
            404: "Zadaný dodavatel neexistuje!",
            409: "Dodavatele nelze smazat kvůli jeho vztahům k dalším objektům!"
        }
    },
    status: {
        404: "Zadaný dodavatel neexistuje!",
        409: "Vyplněné IČO již má jiný dodavatel!"
    }
};
