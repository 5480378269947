import Vue from "vue";

// Share Cache values across all components which use this mixin
const ManufacturerCache = {};
let trigger = Vue.observable({count: 0});

const ReactiveManufacturerCacheMixin = {
    computed: {
        loadingLabel: function () {
            return `<${this.$t('base.loading')}...`;
        },
        ManufacturerCache: function () {
            // We need this to trigger an update in each component which might be using this Mixin simultaneously
            trigger.count;
            return ManufacturerCache;
        }
    },
    methods: {
        cacheManufacturer: function (runningRequest, key, showLoadingInfo = true) {
            ManufacturerCache[key] = showLoadingInfo ? this.loadingLabel : null;
            return runningRequest.then(response => {
                ManufacturerCache[key] = response.data;
                trigger.count++;
                return response;
            });
        }
    }
};

export {ReactiveManufacturerCacheMixin};