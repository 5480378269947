<template>
  <div>
    <v-combobox
      v-if="parametersWithMultipleValues.includes(carrierServiceParameter.name)"
      v-model="multipleValue"
      :label="$t('tasks.externalOrder.carrierServiceParameters.value')"
      prepend-icon="$attributeValue"
      :rules="carrierServiceParameter.required ? [formRules.required] : []"
      :suffix="carrierServiceParameter.required ? '*' : ''"
      :disabled="readOnly"
      multiple
      chips
      small-chips
      deletable-chips
      clearable
    />
    <v-text-field
      v-else
      v-model="
        /* eslint-disable-next-line vue/no-mutating-props */
        carrierServiceParameter.value
      "
      :label="$t('tasks.externalOrder.carrierServiceParameters.value')"
      prepend-icon="$attributeValue"
      :rules="carrierServiceParameter.required ? [formRules.required] : []"
      :suffix="carrierServiceParameter.required ? '*' : ''"
      :disabled="readOnly"
    />
  </div>
</template>

<script>
    import formRules from "@/utils/formRules";
    import {carrierServiceParametersWithMultipleValues} from "@/app/carriers/definitions/carrierServiceParameters";

    export default {
        name: "CarrierServiceParameterValue",
        props: {
            carrierServiceParameter: {
                type: Object,
                default: () => {}
            },
            readOnly: {
                type: Boolean,
                default: false,
            },
        },
        data: () => ({
            formRules: formRules,
            parametersWithMultipleValues: carrierServiceParametersWithMultipleValues
        }),
        computed: {
            multipleValue: {
                get: function () {
                    if (!this.carrierServiceParameter.value) {
                        return [];
                    }
                    return JSON.parse(this.carrierServiceParameter.value);
                },
                set: function (value) {
                    // eslint-disable-next-line vue/no-mutating-props
                    this.carrierServiceParameter.value = JSON.stringify(value);
                }
            }
        }
    };
</script>

<style scoped>

</style>