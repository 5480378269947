import {ManufacturerAPI} from "@/api/ManufacturerAPI";
import {manufacturerLabel} from "@/utils/filters";

export function manufacturers(multiple = false, disableaAutoselect = false) {
    return {
        apiDataSource: ManufacturerAPI.getAll.bind(ManufacturerAPI),
        resultTransform: el => ({
            text: manufacturerLabel(el),
            value: el.id
        }),
        multiple: multiple,
        disableAutoselectFirst: disableaAutoselect
    };
}