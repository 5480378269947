<template>
  <div>
    <ConfirmDeleteDialog
      v-if="deleteDialog"
      :show.sync="deleteDialog"
      :text="details.name + ', ' + details.model + ', ' + details.manufacturer.name"
      @confirm-delete="deleteProduct"
    />
    <x-toolbar
      :title="pageTitle"
    />
    <v-card
      v-if="details"
      class="ma-1"
    >
      <v-container fluid>
        <v-layout wrap>
          <v-flex
            xs12
            class="text-h5 ma-3"
          >
            <v-btn
              fab
              outlined
              small
              class="mr-2"
              @click="update"
            >
              <v-icon>
                $updateItem
              </v-icon>
            </v-btn>
            <v-btn
              v-if="!details.deleted"
              fab
              outlined
              small
              color="error"
              class="mr-3"
              @click="deleteDialog = true"
            >
              <v-icon>
                $deleteObject
              </v-icon>
            </v-btn>
            <span
              :class="{'text--disabled': !details.visible}"
            >
              {{ details.name }}
            </span>
            {{ details.deleted ? (' - ' + $t('products.deleted')) : '' }}
            {{ (!details.visible && !details.deleted) ? (' - ' + $t('products.notVisible')) : '' }}
            <v-btn-toggle
              :rounded="$vuetify.breakpoint.smAndUp"
              dense
              class="ml-3 float-right"
              :class="{'buttonGroupSmall mt-3': $vuetify.breakpoint.xs}"
            >
              <v-btn
                :to="statusLink"
                outlined
              >
                <v-icon class="mr-2">
                  $stockStatus
                </v-icon>
                <span v-show="$vuetify.breakpoint.mdAndUp">
                  {{ $t('products.pieces.status') }}
                </span>
              </v-btn>
              <v-btn
                :to="movementsLink"
                outlined
              >
                <v-icon class="mr-2">
                  $stockMovements
                </v-icon>
                <span v-show="$vuetify.breakpoint.mdAndUp">
                  {{ $t('products.pieces.movements') }}
                </span>
              </v-btn>
              <v-btn
                :to="stockTakingLink"
                outlined
              >
                <v-icon class="mr-2">
                  {{ taskIcons[taskTypes.STOCK_TAKING] }}
                </v-icon>
                <span v-show="$vuetify.breakpoint.mdAndUp">
                  {{ $t('tasks.stockTaking.name') }}
                </span>
              </v-btn>
            </v-btn-toggle>
          </v-flex>
          <v-flex
            sm4
            xs12
          >
            <div>
              <span class="text-caption">{{ $t('products.model') + ': ' }}</span>
              {{ details.model }}
            </div>
            <div>
              <span class="text-caption">{{ $t('products.manufacturer.label') + ': ' }}</span>
              {{ details.manufacturer.name }}
              <router-link :to="'/manufacturers/' + details.manufacturer.id + '/update'">
                <v-icon small>
                  $openItem
                </v-icon>
              </router-link>
            </div>
            <div>
              <span class="text-caption">{{ $t('products.type.label') + ': ' }}</span>
              {{ details.type.default ? $t('products.types.default') : details.type.name }}
              <router-link
                v-if="!details.type.default"
                :to="'/products/types/' + details.type.id + '/update'"
              >
                <v-icon small>
                  $openItem
                </v-icon>
              </router-link>
            </div>
            <div v-if="details.measure_unit !== ''">
              <span class="text-caption">{{ $t('products.measure_unit') + ': ' }}</span>
              {{ details.measure_unit }}
            </div>
            <div>
              <span v-if="details.can_have_batch">
                {{ $t('products.can_have_batch') }}
              </span>
              <span v-if="details.can_have_serial_number">
                {{ $t('products.can_have_serial_number') }}
              </span>
            </div>
          </v-flex>
          <v-flex
            sm4
            xs12
          >
            <div>
              <span class="text-caption">
                {{ $t('products.suggested_buy_price') + ': ' }}
              </span>
              <v-tooltip top>
                <template #activator="{ on }">
                  <span v-on="on">
                    {{ details.suggested_buy_price_without_vat | numberToFixedLocalized(2) }}
                  </span>
                </template>
                <span>
                  {{ details.suggested_buy_price_with_vat | numberToFixedLocalized(2) }} {{ $t('products.vat_included') }}
                </span>
              </v-tooltip>
            </div>
            <div>
              <span class="text-caption">
                {{ $t('products.suggested_sell_price') + ': ' }}
              </span>
              <v-tooltip top>
                <template #activator="{ on }">
                  <span v-on="on">
                    {{ details.suggested_sell_price_without_vat | numberToFixedLocalized(2) }}
                  </span>
                </template>
                <span>
                  {{ details.suggested_sell_price_with_vat | numberToFixedLocalized(2) }} {{ $t('products.vat_included') }}
                </span>
              </v-tooltip>
            </div>
            <div>
              <span class="text-caption">
                {{ $t('products.price_vat') + ': ' }}
              </span>
              {{ details.price_vat | vatRate }}
            </div>
          </v-flex>
          <v-flex
            sm4
            xs12
          >
            <EntityHistory :changes="details" />
          </v-flex>
        </v-layout>
        <v-layout wrap>
          <v-flex
            v-show="details.description"
            xs12
          >
            <div class="text-caption">
              {{ $t('products.description') + ': ' }}
              <v-btn
                text
                @click="showDescription = !showDescription"
              >
                <v-icon
                  :class="{'upsideDown': !showDescription}"
                >
                  $showLess
                </v-icon>
                {{ $t('base.' + (showDescription ? 'hide' : 'view')) }}
              </v-btn>
            </div>
            <div v-show="showDescription">
              <!-- eslint-disable-next-line vue/no-v-html -->
              <span v-html="details.description" />
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
    <template v-else>
      <v-progress-linear
        indeterminate
      />
    </template>
    <div>
      <masonry
        :cols="$vuetify.breakpoint.lgAndUp ? 2 : 1"
        :gutter="0"
      >
        <div
          v-if="details !== null"
          class="pa-1"
        >
          <ProductAttributesList />
        </div>
        <div
          v-if="defaultInstanceId !== null"
          class="pa-1"
        >
          <v-expansion-panels
            :value="0"
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="text-subtitle-1">
                {{ $t('products.instances.name') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <ProductInstanceBarcodesListComponent
                  :product="details"
                  :instance-id="defaultInstanceId"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <!-- dummy div to keep ProductImages bellow ProductAttributes -->
        <div
          v-if="defaultInstanceId === null"
        />
        <div
          v-if="details !== null"
          class="pa-1"
        >
          <ImagesList />
        </div>
        <div
          v-if="(details !== null && (details.can_have_batch || details.can_have_serial_number))"
          class="pa-1"
        >
          <ProductInstancesList
            :instance-type="details.can_have_batch ? InstanceType.BATCH : InstanceType.SERIAL_NUMBER"
          />
        </div>
        <div
          v-if="details !== null"
          class="pa-1"
        >
          <ProductInstancesList
            instance-type="CUSTOM"
          />
        </div>
      </masonry>
      <div v-if="details">
        <ProductOverview
          class="mt-1"
          :product="details"
        />
      </div>
    </div>
    <x-btn-fab-expander
      :actions="fabActions"
    />
  </div>
</template>

<script>
    import {CreateNewBarcodeMixin} from "@/app/mixins/CreateNewBarcodeMixin";
    import {EventsListenerMixin} from "@/app/mixins/EventsListenerMixin";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {taskLinks, taskTypes, taskIcons} from "@/enum/task_type";
    import {tabTitle} from "@/utils/string";
    import {ProductAPI} from "@/api/ProductAPI";
    import EntityHistory from "@/app/components/EntityHistory.component";
    import ProductAttributesList from "@/app/products/attributes/components/ProductAttributesList.component";
    import ImagesList from "@/app/images/components/ImagesList.component";
    import {productFabActions} from "@/app/products/definitions/productFabActions.definition";
    import ProductInstancesList from "@/app/products/instances/components/ProductInstancesList.component";
    import ProductInstanceBarcodesListComponent
        from "@/app/products/instances/components/ProductInstanceBarcodesList.component";
    import ProductOverview from "@/app/products/components/ProductOverview.component";
    import {InstanceType} from "@/enum/instance_type";
    import {APIFilterOP, APIFilters} from "@/service/APIFilters";
    import ConfirmDeleteDialog from "@/app/components/ConfirmDeleteDialog.component";
    import {InstanceCacheMixin} from "@/app/mixins/InstanceCacheMixin";

    export default {
        name: "ProductShow",
        components: {
            ConfirmDeleteDialog,
            ProductOverview,
            ProductInstanceBarcodesListComponent,
            ProductInstancesList,
            ImagesList, ProductAttributesList, EntityHistory
        },
        mixins: [EventsListenerMixin, CreateNewBarcodeMixin, RouteParamsMapperMixin, InstanceCacheMixin],
        data: () => ({
            details: null,
            showDescription: false,
            defaultInstanceId: null,
            taskIcons: taskIcons,
            taskTypes: taskTypes,
            InstanceType: InstanceType,
            deleteDialog: false,
        }),
        computed: {
            events: function () {
                return {
                    'onBarcodeRead': this.createNewBarcode
                };
            },
            pageTitle: function () {
                let title = this.$t(this.$route.meta.title);
                if (this.details !== null) {
                    title = this.details.name;
                }
                if (!this._inactive) {
                    document.title = tabTitle(title);
                }
                return title;
            },
            fabActions: function () {
                return productFabActions(this.productId, this.defaultInstanceId,
                                         this.details !== null ? this.details.can_have_batch : false,
                                         this.details !== null ? this.details.can_have_serial_number : false);
            },
            statusLink: function () {
                return '/status?product.id=' + this.productId;
            },
            movementsLink: function () {
                return '/movements?product.id=' + this.productId;
            },
            stockTakingLink: function () {
                return taskLinks[taskTypes.STOCK_TAKING] + '/create?productId=' + this.productId;
            }
        },
        createdOrActivated: function () {
            ProductAPI.get(this.productId)
                .then(response => {
                    this.details = response.data;
                    const filter = {
                        [APIFilterOP.EQUALS]: {
                            'type.name': InstanceType.NORMAL
                        }
                    };
                    ProductAPI.getAllInstances(this.productId, {
                        page: 1,
                        itemsPerPage: 1,
                        filter: APIFilters.makeFilter(filter)
                    })
                        .then(response => {
                            this.defaultInstanceId = response.data.page_count === 1 ? response.data.items[0].id : null;
                        });
                }).catch(err => {
                    this.snack(err);
                    this.$router.push('/products');
                });
        },
        methods: {
            update: function () {
                this.$router.push('/products/' + this.productId + '/update');
            },
            deleteProduct: function () {
                this.deleteDialog = false;
                ProductAPI.delete(this.details.id)
                    .then(() => {
                        this.advancedSnack({
                            text: 'products.delete.done',
                            params: [this.details.name]
                        });
                        this.clearInstanceCachesByProduct(this.details.id);
                        this.$router.push('/products');
                    }).catch(this.snack);
            }
        }
    };
    // TODO improve design
    // TODO use masonry as on Dashboard
    // TODO show loading status
</script>

<style scoped lang="sass">
.buttonGroupSmall
  display: block
  border-left-width: thin
</style>
