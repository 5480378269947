import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLazy } from 'vuetify/lib/components/VLazy';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{key:_vm.locationId,staticClass:"card-list-item overflow-hidden",class:{
    'mx-3 mt-3 pa-3 text--disabled': !_vm.active,
    'mx-1 mt-3 pa-2 elevation-8': _vm.active
  },attrs:{"color":_vm.active ? _vm.activeCardColor : '',"outlined":_vm.items.length === 0}},[(_vm.locationId)?_c('div',{staticClass:"pa-2"},[_vm._t("items-location-header",function(){return [_c('TaskItemsLocationHeader',{attrs:{"location-id":_vm.locationId}})]})],2):_vm._e(),(_vm.items.length > 0)?_c('div',{staticClass:"d-flex",class:{ 'flex-column': _vm.$vuetify.breakpoint.mdAndDown }},[_c('div',{staticClass:"mt-1",class:{ 'mb-2': _vm.$vuetify.breakpoint.mdAndDown },staticStyle:{"max-width":"100% /* required for ellipsis to work */"}},[_c('transition-group',{attrs:{"name":"chip-list","mode":"out-in"}},_vm._l((_vm.items),function(item){return _c(VLazy,{key:item.id,staticClass:"chip-list-item d-block",attrs:{"transition":"slide-y-reverse-transition","min-height":"32px"}},[_c('div',[_vm._t("card-item",null,{"item":item})],2)])}),1)],1)]):(!_vm.itemsLoading && _vm.single && _vm.cardType === _vm.TaskItemsCardType.TO_MOVE && _vm.itemQuantityOnCard !== 0)?_c(VAlert,{attrs:{"type":"error","text":""}},[_vm._v(" "+_vm._s(_vm.$t('tasks.itemsCard.stolenItems'))+" ")]):_c('span',{staticClass:"text--disabled"},[_c(VIcon,{staticClass:"mb-1 mx-2"},[_vm._v(" $nothing ")]),_vm._v(" "+_vm._s(_vm.$t('base.nothing'))+" ")],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }