<template>
  <div :class="{'error--text': priceWithVat < 0}">
    <template v-if="realProduct">
      <div>{{ priceWithVat | numberToFixedLocalized(2) }}</div>
      <div class="text--disabled">
        {{ $t('products.withoutVat') }} {{ priceWithoutVat | numberToFixedLocalized(2) }}
      </div>
    </template>
    <v-progress-circular
      v-else
      indeterminate
    />
  </div>
</template>

<script>
    export default {
        name: "ProductSellPrice",
        props: {
            product: {
                type: [String, Object],
                default: () => null
            },
            priceTransform: {
                type: Function,
                default: price => price
            }
        },
        computed: {
            realProduct: function () {
                if (!this.product || typeof this.product === "string")
                    return null;
                return this.product;
            },
            priceWithVat: function () {
                if (!this.realProduct)
                    return null;
                return this.priceTransform(this.realProduct.suggested_sell_price_with_vat);
            },
            priceWithoutVat: function () {
                if (this.priceWithVat === null)
                    return null;
                return this.priceWithVat / (1 + Number(this.realProduct.price_vat));
            }
        }
    };
</script>

<style scoped>

</style>