<template>
  <div>
    <ConfirmDeleteDialog
      v-if="deletedInstance !== null"
      :show.sync="confirmDialog"
      :text="instanceType === InstanceType.SERIAL_NUMBER ? deletedInstance.serial_number : deletedInstance.code"
      @confirm-delete="reallyDeleteInstance"
    />
    <v-expansion-panels
      :value="0"
    >
      <v-expansion-panel>
        <v-expansion-panel-header class="text-subtitle-1">
          <div>
            <span>
              {{ $t(header) }}
            </span>
            <v-tooltip
              v-if="instanceType === 'CUSTOM'"
              class="ml-2"
              right
            >
              <template #activator="{ on }">
                <span v-on="on"><v-icon>$info</v-icon></span>
              </template>
              <span>{{ $t('products.customInstances.hint') }}</span>
            </v-tooltip>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <x-data-table
            :headers="headers"
            :loading.sync="loading"
            :items.sync="items"
            :actions="actions"
            :api-data-source="apiDataSource"
            :api-data-source-all-pages="apiDataSourceAllPages"
            :reload="reload"
          >
            <template #item.type="{ item }">
              <td>{{ item.type | instanceType }}</td>
            </template>
            <template #item.manufactured_date="{ item }">
              <DateTimeWithTooltip
                :date-time="item.manufactured_date"
                abs-date-first
              />
            </template>
            <template #item.expire_date="{ item }">
              <DateTimeWithTooltip
                :date-time="item.expire_date"
                abs-date-first
              />
            </template>
            <template #footer>
              <TableAddItemButton
                :to="instanceCreateLink"
                :label="instanceCreateLabel"
              />
            </template>
          </x-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
    import {ProductAPI} from "@/api/ProductAPI";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {createHeaders} from "@/utils/table";
    import {productInstanceTable, productSeriesTable, productBatchesTable} from "@/app/products/instances/definitions/productInstance.table";
    import TableAddItemButton from "@/app/components/table/TableAddItemButton.component";
    import {InstanceType} from "@/enum/instance_type";
    import {APIFilterOP, APIFilters} from "@/service/APIFilters";
    import ConfirmDeleteDialog from "@/app/components/ConfirmDeleteDialog.component";
    import DateTimeWithTooltip from "@/app/components/DateTimeWithTooltip.component";

    export default {
        name: "ProductInstancesList",
        components: {DateTimeWithTooltip, TableAddItemButton, ConfirmDeleteDialog},
        mixins: [RouteParamsMapperMixin],
        props: {
            instanceType: {
                type: String,
                default: 'CUSTOM'
            }
        },
        data: () => ({
            loading: false,
            items: [],
            apiFilter: [],
            reload: 0,
            deletedInstance: null,
            confirmDialog: false,
            InstanceType: InstanceType
        }),
        computed: {
            header: function () {
                switch (this.instanceType) {
                case InstanceType.SERIAL_NUMBER:
                    return 'products.serial_numbers.name';
                case InstanceType.BATCH:
                    return 'products.batches.name';
                default:
                    return 'products.customInstances.name';
                }
            },
            instanceCreateLink: function () {
                switch (this.instanceType) {
                case InstanceType.SERIAL_NUMBER:
                    return '/products/' + this.productId + '/serial-numbers/create';
                case InstanceType.BATCH:
                    return '/products/' + this.productId + '/batches/create';
                default:
                    return '/products/' + this.productId + '/custom-instances/create';
                }
            },
            instanceCreateLabel: function () {
                switch (this.instanceType) {
                case InstanceType.SERIAL_NUMBER:
                    return 'products.serial_numbers.create.titleSimple';
                case InstanceType.BATCH:
                    return 'products.batches.create.titleSimple';
                default:
                    return 'products.customInstances.create.titleSimple';
                }
            },
            headers: function () {
                const langPath = 'products.instances.';
                switch (this.instanceType) {
                case InstanceType.SERIAL_NUMBER:
                    return createHeaders(productSeriesTable, langPath);
                case InstanceType.BATCH:
                    return createHeaders(productBatchesTable, langPath);
                default:
                    return createHeaders(productInstanceTable, langPath);
                }
            },
            actions: function () {
                const actions = [
                    {
                        action: this.showBarcodes,
                        icon: '$barcode',
                        label: 'products.instances.barcodes.link'
                    },
                ];
                if (this.instanceType !== InstanceType.SERIAL_NUMBER) {
                    actions.push({
                        action: this.updateItem,
                        icon: '$updateItem',
                        label: 'base.edit'
                    });
                }
                actions.push({
                    action: this.showStockStatus,
                    icon: '$stockStatus',
                    label: 'products.pieces.status'
                }, {
                    action: this.showStockMovements,
                    icon: '$stockMovements',
                    label: 'products.pieces.movements'
                });
                actions.push({
                    action: this.deleteItem,
                    icon: '$deleteTableItem',
                    label: 'base.delete',
                });
                return actions;
            },
            apiDataSource: function () {
                if (this.instanceType === InstanceType.BATCH) {
                    return ProductAPI.getAllBatches.bind(ProductAPI, this.productId);
                }
                return ProductAPI.getAllInstances.bind(ProductAPI, this.productId, this.apiFilterParam);
            },
            apiDataSourceAllPages: function () {
                if (this.instanceType === InstanceType.BATCH) {
                    return ProductAPI.getAllBatchesAllPages.bind(ProductAPI, this.productId);
                }
                return ProductAPI.getAllInstancesAllPages.bind(ProductAPI, this.productId, this.apiFilterParam);
            },
            apiFilterParam: function () {
                return this.apiFilter.length ? {filter: APIFilters.makeFilter(this.apiFilter)} : {};
            }
        },
        createdOrActivated: function () {
            if (this.instanceType === InstanceType.SERIAL_NUMBER) {
                this.apiFilter.push({
                    [APIFilterOP.EQUALS]: {
                        'type.name': this.instanceType
                    }
                });
            } else {
                this.apiFilter.push({
                    [APIFilterOP.AND] : [
                        {
                            [APIFilterOP.NOT_EQUALS]: {
                                'type.name': InstanceType.NORMAL
                            }
                        },
                        {
                            [APIFilterOP.NOT_EQUALS]: {
                                'type.name': InstanceType.SERIAL_NUMBER
                            }
                        },
                        {
                            [APIFilterOP.NOT_EQUALS]: {
                                'type.name': InstanceType.BATCH
                            }
                        }
                    ]
                });
            }
        },
        methods: {
            getItemInstanceId: function (item) {
                if (this.instanceType === InstanceType.BATCH) {
                    return item.product_instance_id;
                }
                return item.id;
            },
            showBarcodes: function (item) {
                this.$router.push('/products/' + this.productId + '/instance/' + this.getItemInstanceId(item) + '/barcodes');
            },
            showStockStatus: function (item) {
                this.$router.push('/status?product.id=' + this.productId + '&product_instance.id=' + this.getItemInstanceId(item));
            },
            showStockMovements: function (item) {
                this.$router.push('/movements?product.id=' + this.productId + '&product_instance.id=' + this.getItemInstanceId(item));
            },
            updateItem: function (item) {
                if (this.instanceType === InstanceType.BATCH) {
                    this.$router.push('/products/' + this.productId + '/batches/' + item.code);
                } else {
                    this.$router.push('/products/' + this.productId + '/custom-instances/' + item.id + '/update');
                }
            },
            deleteItem: function (item) {
                this.deletedInstance = item;
                this.confirmDialog = true;
            },
            reallyDeleteInstance: async function () {
                this.loading = true;
                this.confirmDialog = false;
                switch (this.instanceType) {
                case InstanceType.SERIAL_NUMBER :
                    await this.reallyDeleteSerialNumber();
                    break;
                case InstanceType.BATCH :
                    await this.reallyDeleteBatch();
                    break;
                default :
                    await this.reallyDeleteCustom();
                    break;
                }
                this.reload++;
                this.loading = false;
            },

            reallyDeleteCustom: async function () {
                await ProductAPI.deleteCustomInstance(this.productId, this.deletedInstance.id)
                    .then(() => {
                        this.advancedSnack({
                            text: 'products.customInstances.delete.done',
                            params: [this.deletedInstance.serial_number]
                        });
                    }).catch(this.snack);
            },

            reallyDeleteBatch: async function () {
                await ProductAPI.deleteBatch(this.productId, this.deletedInstance.code)
                    .then(() => {
                        this.advancedSnack({
                            text: 'products.batches.delete.done',
                            params: [this.deletedInstance.code]
                        });
                    }).catch(this.snack);
            },

            reallyDeleteSerialNumber: async function () {
                await ProductAPI.deleteSerial(this.productId, this.deletedInstance.serial_number)
                    .then(() => {
                        this.advancedSnack({
                            text: 'products.serial_numbers.delete.done',
                            params: [this.deletedInstance.serial_number]
                        });
                    }).catch(this.snack);
            },
        }
    };
</script>

<style scoped>

</style>
