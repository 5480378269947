import {EventBus} from "@/service/EventBus";

const BarcodeReaderMixin = {
    methods: {
        readingDoneNoVibrate: function () {
            EventBus.$emit('reading-done', false);
        },
        readingDone: function (doVibrate = true) {
            EventBus.$emit('reading-done', doVibrate);
        },
        readingFail: function (message) {
            EventBus.$emit('reading-fail', message);
        }
    }
};

export {BarcodeReaderMixin};