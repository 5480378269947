import {PrintType} from "@/enum/print_type";
import {ShipmentState} from "@/enum/shipment_state";
import {APIFilterOP} from "@/service/APIFilters";
import {AttributeDataTypes} from "@/enum/attribute_data_types";

export default {
    and: "és",
    or: "vagy",
    close: "Bezárás",
    undo: "Visszavonás",
    open: "Nyisd ki",
    used: "használt",
    free: "ingyenes",
    reset: "Visszaállítás",
    about: "A FulWMS-ről",
    loading: "Betöltés",
    cancelLoading: "Törlés, vissza az áttekintés nézetre",
    cancelLoadingHint: "A háttérben futó művelet leállhat, de nem feltétlenül áll le!",
    home: "Otthon",
    notFound: "Nem található",
    pageNotFound: "Az oldal nem található",
    darkMode: "Éjszakai üzemmód",
    productLabels: "Tárolt készlet címkék",
    productModel: {
        0: "Név",
        1: "Modell"
    },
    showProductImagesInTasks: "Termékfotók megjelenítése a feladatokban",
    pickFromMobileLocation: "Tárolt készlet elemeinek felvétele mobil helyről",
    automagicallyChooseInstance: {
        label: "Bizonyos elemek automatikus kiválasztása",
        hint: "Címke nyomtatása, ha egy adott tétel helytelenül van betöltve."
    },
    onBeep: "Amikor sípolt",
    search: "Keresés",
    searchAll: "Keresés mindenhol",
    searchHint: "Keresés a {0} és {1}.",
    searchHintSingle: "Keresés a {0}.",
    searchResult: {
        searchesIn: "Keresési feladatok (szám, külső azonosító, státusz, prioritás és típus), készletelemek (gyártó, név és model), raktárak (név), személyek (ico) és vonalkódok keresése.",
        tasksById: "Feladatok",
        tasksByExternalId: "Feladatok külső azonosítóval {0}",
        tasksByState: "Feladatok a tárolt készlet {0} elemeinek állapota alapján",
        tasksByPriority: "Feladatok a tárolt készlet elemeinek {0} prioritása alapján",
        tasksByType: "Feladatok a tárolt készlet elemeinek {0} típusa alapján",
        productsByBarcode: "Feladatok a tárolt készlet elemeinek vonalkódja alapján",
        productsByManufacturer: "Feladatok a tárolt készlet elemeinek {0} gyártója alapján",
        productsByName: "Feladatok a tárolt készlet elemeinek {0} elnevezése alapján",
        productsByModel: "Feladatok a tárolt készlet elemeinek {0} modelje alapján",
        stocksByName: "Feladatok a tárolt készlet elemeinek {0} elnevezése alapján",
        locationsByBarcode: "Feladatok helyszín vonalkód alapján",
        buyersByIco: "Feladatok a vevő ICO száma alapján",
        suppliersByIco: "Feladatok a szállító ICO száma alapján",
        stockOwnersByIco: "Feladatok a tárolt készlet tulajdonosának az ICO száma alapján",
    },
    advancedFilter: "Összetett szűrés",
    filterBy: "Szűrő",
    useHeaderToSearch: "A kereséshez használja a táblázat fejlécében található mezőket",
    orWriteYourOwn: "vagy kezdje el írni a sajátját",
    noIco: "Hiányzó ICO szám",
    actions: "Akciók",
    nothing: "Semmi",
    no: "Nem",
    yes: "Igen",
    of: "/",
    pcs: "db",
    view: 'Nézet',
    hide: 'Elrejteni',
    showAll: "Mutass mindent",
    hideAll: "Mindent elrejteni",
    edit: 'Szerkesztés',
    delete: "Törlés",
    confirmDelete: "Biztosan törölni szeretné?",
    reload: "Újratöltés",
    save: "Mentés",
    saved: "Megmentett",
    notSave: "Ne mentse",
    cancel: "visszavonás",
    active: 'Aktív',
    clickToCopy: "Kattintson a másoláshoz",
    copyToClipboard: "Másolás a vágólapra",
    copyToClipboardDone: "'{0}' másolva!",
    copyToClipboardFail: "A másolás sikertelen!",
    goBack: "Vissza az előző oldalra",
    goUp: "Vissza egy szinttel feljebb",
    lang: "Nyelv",
    help: {
        title: "Segítség"
    },
    sort: {
        DESC: 'ereszkedő',
        ASC: "emelkedő",
    },
    cache: {
        title: "Cache-kezelés",
        contents: "Gyorsítótár tartalom",
        length: "Terjedelem",
        size: "Méret",
        clear: "Töröl",
        clearAll: "Törölj mindent",
        taskDetails: "A feladat részletei",
        barcodes: "Vonalkód,",
        buyers: "Vásárlók",
        locations: "Helyszínek",
        allowedLocationIds: "A raktárcikkek megengedett helyei",
        instances: "Tárolt készlet elemeinek példányszáma",
        products: "Tárolt készletelem példány",
        instanceBarcodes: "Készleten lévő vonalkódok",
        instanceTypes: "Különleges esetek",
        users: "Felhasználó"
    },
    login: "Bejelentkezés",
    continueAs: "Folytatás mint",
    loginOther: "Bejelentkezés mint",
    logout: "Kijelentkezés",
    confirmLogout: "Biztos, hogy ki akarsz jelentkezni?",
    logoutHint: "Kijelentkezik az összes megnyitott lapról.",
    version: "Verzió",
    offline: "Nincs szerverkapcsolat!",
    readonly: "Az alkalmazás csak olvasható módban van!",
    download: "{0} letöltése",
    uploadAttachment: "Melléklet feltöltése",
    filter: {
        [APIFilterOP.AND]: 'és',
        [APIFilterOP.OR]: 'és vagy',
        [APIFilterOP.IS_NULL]: '{0} üres',
        [APIFilterOP.IS_NOT_NULL]: '{0} nem üres',
        [APIFilterOP.ARRAY_CONTAINS]: '{0} lista tartalmazza {1}',
        [APIFilterOP.ARRAY_NOT_CONTAINS]: '{0} lista nem tartalmazza {1}',
        [APIFilterOP.ARRAY_EMPTY]: '{0} lista üres',
        [APIFilterOP.ARRAY_NOT_EMPTY]: '{0} lista nem üres',
        [APIFilterOP.LIKE]: '{0} tartalmaz {1}',
        [APIFilterOP.NOT_LIKE]: '{0} nem tartalmazza {1}',
        [APIFilterOP.EQUALS]: '{0} egyezik {1}',
        [APIFilterOP.NOT_EQUALS]: '{0} nem egyenlő {1}',
        [APIFilterOP.GREATER_THAN]: '{0} nagyobb, mint {1}',
        [APIFilterOP.LOWER_THAN]: '{0} kisebb, mint {1}',
        [APIFilterOP.GREATER_THAN_OR_EQUAL]: '{0} nagyobb vagy egyenlő, mint {1}',
        [APIFilterOP.LOWER_THAN_OR_EQUAL]: '{0} kisebb vagy egyenlő, mint {1}',
        [APIFilterOP.BETWEEN]: '{0} között {1} és {2}',
        [APIFilterOP.IN]: '{0} a következők egyike: {1}',
        [APIFilterOP.NOT_IN]: '{0} nem tartozik egyikhez sem: {1}'
    },
    filterConfig: {
        filter: "Szűrő",
        invalid: "A szűrő nem érvényes!",
        sort: 'Rendezés',
        condition: "Feltétel",
        addCondition: "Feltétel hozzáadása",
        splitCondition: "Feltétel állapota",
        removeCondition: "Feltétel törlése",
        nestedGroup: "A raktározás feltételei",
        nonNested: "Közvetlen feltételek",
        addFilter: "Szűrő hozzáadása",
        addSort: "Rendezés kategóriák szerint",
        removeSort: "Rendezés törlése",
        sortThen: "további rendezés",
        fill: "Töltse ki a",
        fillFrom: "Töltsd ki innen",
        fillTo: "Töltsd ki eddig",
        attributeChanged: "Az attribútum {0}-ról {1}-re változott.",
        booleanValues: {
            true: "Igaz",
            false: "Hamis"
        },
        select: "Válassza ki a",
        fromInputError: "Az értéknek kisebbnek vagy egyenlőnek kell lennie, mint {0}",
        toInputError: "Az értéknek nagyobbnak vagy egyenlőnek kell lennie, mint {0}",
    },
    regex: {
        '^': 'Kezdje',
        '$': 'Vége',
        dot: 'Bármilyen karakter',
        '?': 'Nincs vagy csak egy előfordulása',
        '*': 'Nincs vagy tetszőleges számú előfordulás',
        "+": "Egy vagy több előfordulás",
        "\\": "A következő karakter nem különleges karakter",
        '()': "Karaktercsoport-határolás",
        squareBrackets: "Egy adott karakter keresése a csoportban",
        "{}": "Meghatározott számú ismétlés megadása",
    },
    table: {
        batchActions: "Tömeges rendezvények",
        removeSelection: "Kiválasztás eltávolítása",
        batchActionDisabledByFulltext: "Nem kombinálható teljes szöveges kereséssel. Használjon összetett szűrés.",
        batchAllFiltered: 'Minden szűrésre',
        batchAllSelected: "Minden kiválasztott számára",
        filter: {
            chooseAttr: '' // intentionally blank
        }
    },
    reports: {
        title: "Jelentések",
        domain: "Jelentési terület",
        type: "A jelentés típusa",
        new_location: "Ez a jelentés mostantól a 'Jelentések' fülön található.",
        redirect: "Átirányítás a"
    },
    shipping: {
        link: 'Küldemények',
        title: "Küldemények",
        optional: "Választható szűrők",
        table: {
            state: "Állapot",
            external_order_external_order_id: 'Külső rendelési szám',
            external_order_id: "Megrendelés",
            stock_picking_id: "Tárolt készlet elem azonosító",
            buyer: {
                id: "Vásárlő",
                name: "Vásárlő",
                email: "Email",
                phone: "Telefon"
            },
            subordinate_stock_id: "Alraktár készlet elem azonosító",
            carrier_id: "Szállító azonosító",
            last_validated: "Ellenőrizve",
            carrier_api_error_messages: "Szolgáltatói hibák",
            external_order_created_at: 'Rendelés létrehozva',
            created_at: 'Szállítmány létrehozva',
            label_first_printed_at: "Címkét nyomtattak először",
            last_protocol_created_at: "Utolsó létrehozott napló",
            sent_date: "Küldés időpontja",
            total_value: "Ár",
            total_weight: "Súly",
            package_count: "Csomagok száma",
            searchHint: "Szállító, ügyfél, e-mail, telefon és külső rendelésszám keresése"
        },
        shipment: {
            show: "Küldemény megjelenítése",
            printLabel: "Küldeménycímke nyomtatása",
            printDetail: {
                label: "Nyomtassa ki az átviteli jelentést",
                hint: "Először szűrjön ki pontosan egy alraktárt és pontosan egy fuvarozót."
            },
            track: "Kövesse nyomon a szállítmányt",
            state: {
                [ShipmentState.CREATED]: "Új",
                [ShipmentState.WAITING_FOR_PICKUP]: "Elküldésre vár",
                [ShipmentState.SENT]: "Elküldve",
                [ShipmentState.BEING_DELIVERED]: "Kézbesítése folyamatban van",
                [ShipmentState.DELIVERED_TO_PICKUP_LOCATION]: "Kézbesítési pontra szállították",
                [ShipmentState.DELIVERED]: "Kézbesítésre került",
                [ShipmentState.RETURNING]: "Visszatér",
                [ShipmentState.RETURNED]: "Visszatért",
                [ShipmentState.LOST]: "Elveszett",
                [ShipmentState.TO_BE_CANCELLED]: "Elmarad",
                [ShipmentState.CANCELLED]: "Törölve",
                [ShipmentState.UNKNOWN]: "Ismeretlen",
            }
        },
        report: {
            link: 'Küldemények jelentés',
            title: 'Küldemények jelentés',
            table: {
                external_order_task_id: 'Megrendelés',
                external_order_states: 'Megrendelés státusza',
                order_id: 'Külső szám',
                buyer_name: 'Vásárlő',
                shipment_state: 'szállítási státusz',
                eshop_created_at: 'Eshop elfogadja',
                wms_created_at: 'Raktáron elfogadta',
                work_started_at: 'Munka kezdete',
                work_ended_at: 'Munka vége',
                carrier_accepted_at: 'Fuvarozó elfogadta',
                carrier_delivered_at: 'Szállított',
                searchHint: "Megrendelésszám, külső szám és ügyfél keresése"
            }
        },
        handover: {
            link: 'Küldemények továbbítása',
            title: 'Küldemények továbbítása',

            form: {
                carrierId: "Válasszon egy fuvarozót",
                stockId: "Válasszon ki egy raktárat",
                subStockId: "Csak részkészlet ...",
                selectShipments: "Válassza ki a szállítmányokat",
            },

            table: {
                created_at: "A létrehozás dátuma",
                substock: {
                    id: "Alraktár"
                },
                shipment: {
                    ids: "A szállítmányok száma",
                    detail: "Részlet"
                },
                issueProtocol: "Szállítmányok továbbítása",
                notYetIssued: "Még nem jelent meg"
            },

            handOverAll: "Minden szállítmány továbbítása ({0})",
            reload: "Függő küldemények frissítése",
            waitingForPacking: "Csomagolásra váró küldemények: {0}",

            create: {
                title: "Egyedi jegyzőkönyv kiadása",

                form: {
                    carrierId: "Válasszon egy fuvarozót",
                    stockId: "Válasszon ki egy raktárat",
                    subStockId: "Válasszon ki egy részkészlet",
                    selectShipments: "Válassza ki a szállítmányokat",
                    noShipmentSelect: "Ellenkező esetben az összes átadásra váró szállítmányról jelentést nyomtat.",
                },
            },

            printProtocol: "Nyomtassa ki a naplót",
            noStockShipmentsWaiting: "A kiválasztott raktárból nincs olyan szállítmány, amely a kiválasztott fuvarozónak való továbbításra vár.",
            noShipmentsWithoutProtocol: "Nincs olyan átadandó szállítmány, amely ne szerepelne már a naplóban." + +
                "Ahhoz, hogy egy ilyen szállítmányhoz másik naplót hozzon létre, azt kifejezetten ki kell választani."
        }
    },
    images: {
        name: "Kép",
        plural: "Képek",
        url: "Cím",
        main: "Alapértelmezett kép",
        setMain: "Beállítás mint alapértelmezett kép",
        type: {
            name: "Típus",
            local: "helyi",
            localLabel: "Kép feltöltése",
            external: "külső",
            externalLabel: "Kép beillesztése URL-címről"
        },
        create: {
            titleSimple: "Kép hozzáadása",
            title: "'{0}' kép hozzáadása",
            done: "'{0}' kép hozzáadása",
            unableToLoad: "A kép betöltése sikertelen"
        },
        update: {
            titleSimple: "Kép szerkesztése",
            title: "Kép'{0}' szerkesztése ",
            done: "Kép '{0}' szerkesztve"
        },
        delete: {
            done: "Kép eltávolítva",
            failed: "A képet nem sikerült eltávolítani. Kérjük, próbálja meg később újra."
        },
        store: {
            title: "Tárolja helyben",
            hint_keep_link: "Csak a képre mutató link kerül tárolásra",
            hint_store_link: "A képet letöltik és helyben tárolják, ha mentésre kerül",
            done: "A külső képet sikeresen letöltötték és helyiként tárolták"
        }
    },
    print: {
        sent: "Nyomtatásra elküldve!",
        noConfigFor: "Nincs konfigurálva nyomtatás a következőhöz: {0}!",
        socketTimeout: "Hiba a nyomtatóhoz való csatlakozásban, kérjük, ellenőrizze a konfigurációt.",
        error: 'Valami hiba történt a nyomtatás során, kérjük, ellenőrizze a nyomtatási eredményt.'
    },
    list: {
        loadMore: "Tovább",
        loadingMore: "Betöltés tovább",
        unableToLoad: "Nem sikerült betölteni a listát. Kérjük, próbálja meg újra."
    },
    api: {
        401: "A felhasználó nincs bejelentkezve!",
        403: "Önnek nincs hozzáférése ehhez a művelethez!",
        405: "Ez a művelet jelenleg nem engedélyezett!",
        412: "Az elemet időközben egy másik felhasználó módosította. Kérjük, tegye meg újra a műveletet.",
        unexpectedError: "Váratlan hiba történt. Kérjük, próbálja meg később a műveletet újra.",
        ares: {
            status: {
                404: "A megadott azonosítószámú készlet elem nem található!",
            }
        },
        barcodes: {
            unknown: "Ismeretlen kód",
            status: {
                404: "A kód nem található!"
            }
        },
        shipments: {
            packages: {
                status: {
                    404: "A küldemény vagy csomag nem található!",
                    409: "A csomagot már nem lehetett eltávolítani - a küldeményt már elküldték!"
                }
            },
            protocols: {
                create: {
                    status: {
                        404: "Nem találtuk a szállítót, az alárendelt áruházat vagy valamelyik szállítmányt!",
                        409: "Néhány küldemény nincs 'Elküldésre vár' státuszban, vagy nincs hozzá rendelve kézbesítési szám."
                    }
                },
                getAll: {
                    status: {
                        404: "Nem találtunk szerződött fuvarozót vagy alárendelt boltot!"
                    }
                },
                status: {
                    404: "A kért protokoll nem található!",
                    423: "A kért protokoll még nem áll készen, kérjük, próbálja meg a műveletet később újra.",
                }
            },
            status: {
                404: "A kért szállítmány nem található!",
                423: "A szállítási címke még nem készült el, kérjük, próbálja meg a műveletet később újra.",
                409: "A küldemény nincs szállítható állapotban!"
            }
        },
        shipmentCarrier: {
            contracted: {
                all: {
                    byOwner: {
                        status: {
                            404: "A tárolt készlet tulajdonosa nem található!",
                        }
                    },
                    bySubStock: {
                        status: {
                            404: "A megadott almappa nem létezik!"
                        }
                    }
                },

                status: {
                    404: "A tárolt készlet szállítója vagy tulajdonosa nem található!",
                    409: "A fuvarozót nem sikerült törölni a szerződött fuvarozók listájáról."
                }
            },

            status: {
                404: "A kért fuvarozó nem található!",
            }
        }
    },
    address: {
        street: "Utca",
        house_number: "Házszám",
        suburb: "Cím pontosítás",
        city: "Város",
        postal_code: "Irányítószám",
        phone: "Telefon",
        email: "Email",
        country: "Ország (lokalizált)",
        country_iso_code: "ISO Ország (Angol)",
    },
    bank_account: {
        prefix_number: "Előtag",
        account_number: "Számlaszám",
        bank_code: "Bankkód",
        full_number: "Számlaszám a bankkóddal együtt",
        iban: "IBAN",
        swift: "SWIFT"
    },
    changes: {
        created_at: "Létrehozta a",
        created_by: "Szerző",
        updated_at: "Utoljára módosítva",
        updated_by: "Módosítva",
        noUpdates: "Nincs újabb szerkesztés",
    },
    ares: {
        load: "ARES adatok betöltése",
        validated: "ARES által érvényesítve",
        not_validated: "ARES által nincs érvényesítve",
    },
    print_type: {
        [PrintType.PDF]: "PDF",
        [PrintType.ZPL]: "ZPL",
        [PrintType.ZPLX]: "ZPLX",
        [PrintType.ZPLX2]: "ZPLX2",
        [PrintType.ZPL_PD]: "ZPLPD",
        [PrintType.ESCP]: "ESCP",
        [PrintType.HTML]: "HTML"
    },
    form: {
        rules: {
            mustBeHigherThanFrom: "Nem lehet hamarabb, mint 'tól'!",
            mustBeUpToToday: "Nem lehet később, mint ma!",
            maxDays: "Maximum {0} nap!"
        }
    },
    attributes: {
        dataTypes: {
            [AttributeDataTypes.STRING]: 'Szöveg',
            [AttributeDataTypes.INTEGER]: 'Szám',
            [AttributeDataTypes.FLOAT]: 'Tizedes szám',
            [AttributeDataTypes.BOOLEAN]: 'Igazság érték',
        },
        templates: {
            title: "Attribútumok",
            titleSingle: "Attribútum",
            link: "Attribútumok",
            create: "Attribútum létrehozása",
            update: {
                link: "Attribútum szerkesztése",
                title: "Szerkesztette {0}"
            },
            delete: {
                usage: {
                    loading: "A '{0}' attribútum használatát kérik le.",
                    inUse: "A '{0}' attribútum használatban van, és törlése az értékek elvesztéséhez vezet.",
                    notInUse: "A '{0}' attribútumot nem használják."
                },
                done: "Attribútum {0} törölve",
            },
            form: {
                description: 'Leírás',
                value: "Név",
                data_type: 'Adattípus'
            }
        },
    },
};
