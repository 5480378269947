export default {
    title: "Suppliers",
    link: "Suppliers",

    name: "Name",
    ico: "ID",
    dic: "VAT ID",
    phone: "Phone",
    email: "E-mail",
    website: "Web",
    billing_address: "Billing address",

    list: {
        unableToLoad: "Unable to load list of suppliers. Please try again later."
    },
    show: {
        title: "Supplier",
        tasks: "Delivery arrival and stock loading tasks of supplier"
    },
    create: {
        label: "Create new supplier",
        title: "Create supplier",
        done: "Supplier '{0}' created.",
        undo: {
            done: "Creation of '{0}' successfully canceled!",
            failed: "Failed to undo supplier creation!"
        }
    },
    update: {
        titleSimple: "Update supplier",
        title: "Update {0}",
        done: "Supplier '{0}' updated.",
        undo: {
            done: "Update of '{0}' successfully canceled!",
            failed: "Failed to undo supplier update!"
        }
    },
    delete: {
        done: "'{0}' deleted.",
        failed: "Unable to delete '{0}'. Please try again later.",
        undo: {
            done: "Deletion of '{0}' successfully canceled!",
            failed: "Failed to undo supplier delete!"
        },
        status: {
            404: "Supplier does not exist!",
            409: "Unable to delete supplier due to references to other objects!",
        }
    },
    status: {
        404: "Supplier does not exist!",
        409: "ID is already in use!",
    }
};
