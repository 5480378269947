<template>
  <div>
    <v-expansion-panels
      :value="0"
    >
      <v-expansion-panel>
        <v-expansion-panel-header class="text-subtitle-1">
          {{ $t('suppliers.show.tasks') }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <x-data-table
            :headers="headers"
            :loading.sync="loading"
            :items.sync="items"
            :api-data-source="apiDataSource"
            :api-data-source-all-pages="apiDataSourceAllPages"
            :search-hint="$t('tasks.deliveryAccept.searchHint')"
          >
            <template #item.delivery_accept.id="{ item }">
              <div v-if="item.delivery_accept">
                <v-icon>
                  {{ taskIcons[taskTypes.DELIVERY_ACCEPT] }}
                </v-icon>
                <router-link
                  :to="taskLinks[taskTypes.DELIVERY_ACCEPT] + '/' + item.delivery_accept.id"
                >
                  #{{ item.delivery_accept.id }}
                </router-link>
              </div>
            </template>
            <template #item.delivery_accept.priority="{ item }">
              <div
                v-if="item.delivery_accept"
                :class="'p' + item.delivery_accept.priority"
              >
                {{ item.delivery_accept.priority | taskPriority }}
              </div>
            </template>
            <template #item.delivery_accept.delivery_type="{ item }">
              <template v-if="item.delivery_accept">
                {{ item.delivery_accept.delivery_type | deliveryType }}
              </template>
            </template>
            <template #item.external_order.id="{ item }">
              <span @click.stop>
                <router-link
                  v-if="item.external_order_task_id"
                  :to="taskLinks[taskTypes.EXTERNAL_ORDER] + '/' + item.external_order_task_id"
                >
                  #{{ item.external_order_task_id }}
                </router-link>
              </span>
            </template>
            <template #item.stock_loading.id="{ item }">
              <div v-if="item.stock_loading">
                <v-icon>
                  {{ taskIcons[taskTypes.STOCK_LOADING] }}
                </v-icon>
                <router-link
                  :to="taskLinks[taskTypes.STOCK_LOADING] + '/' + item.stock_loading.id "
                >
                  #{{ item.stock_loading.id }}
                </router-link>
              </div>
            </template>
            <template #item.stock_loading.priority="{ item }">
              <div
                v-if="item.stock_loading"
                :class="'p' + item.stock_loading.priority"
              >
                {{ item.stock_loading.priority | taskPriority }}
              </div>
            </template>
            <template #item.stock_loading.strict_mode="{ item }">
              <template v-if="item.stock_loading">
                {{ item.stock_loading.strict_mode | strictMode }}
              </template>
            </template>
          </x-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {createHeaders} from "@/utils/table";
    import {deliveryAcceptTasksTable} from "@/app/tasks/deliveryAccept/definitions/deliveryAccept.form";
    import {UserAPI} from "@/api/UserAPI";
    import {CreatedOrActivatedMixin} from "@/app/mixins/CreatedOrActivatedMixin";
    import {taskIcons, taskLinks, taskTypes} from "@/enum/task_type";
    import {SupplierAPI} from "@/api/SupplierAPI";

    export default {
        name: 'SupplierTasksList',
        mixins: [RouteParamsMapperMixin, CreatedOrActivatedMixin],
        data: () => ({
            loading: true,
            items: [],
            users: [],
            taskLinks: taskLinks,
            taskTypes: taskTypes,
            taskIcons: taskIcons
        }),
        computed: {
            headers: function () {
                return createHeaders(deliveryAcceptTasksTable(this.users), 'tasks.', false);
            },
            apiDataSource: function () {
                return SupplierAPI.getAllTasks.bind(SupplierAPI, this.supplierId);
            },
            apiDataSourceAllPages: function () {
                return SupplierAPI.getAllTasksAllPages.bind(SupplierAPI, this.supplierId);
            },
        },
        createdOrActivated: function () {
            this.loading = true;
            UserAPI.getAll()
                .then(response => this.users = response.data)
                .finally(() => this.loading = false);
        }
    };
</script>

<style scoped lang="sass">
.p1
  color: var(--v-priorityTextLow-base)

.p3
  color: var(--v-priorityTextHigh-base)
</style>