<template>
  <div>
    <x-toolbar
      :title="pageTitle"
    />
    <template v-if="details">
      <SupplierDetail :details="details" />
      <v-layout wrap>
        <v-flex
          xs12
          class="pa-1"
        >
          <SupplierTasksList />
        </v-flex>
      </v-layout>
    </template>
    <template v-else>
      <v-progress-linear
        indeterminate
      />
    </template>
  </div>
</template>

<script>
    import {tabTitle} from "@/utils/string";
    import {SupplierAPI} from "@/api/SupplierAPI";
    import SupplierDetail from "@/app/suppliers/components/SupplierDetail.component.vue";
    import SupplierTasksList from "@/app/suppliers/components/SupplierTasksList.component.vue";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";

    export default {
        name: "SupplierShow",
        components: {SupplierDetail, SupplierTasksList},
        mixins: [RouteParamsMapperMixin],
        data: () => ({
            details: null
        }),
        computed: {
            pageTitle: function () {
                let title = this.$t(this.$route.meta.title);
                if (this.details !== null) {
                    title = this.details.name;
                }
                if (!this._inactive) {
                    document.title = tabTitle(title);
                }
                return title;
            }
        },
        createdOrActivated: function () {
            SupplierAPI.get(this.supplierId)
                .then(response => {
                    this.details = response.data;
                }).catch(err => {
                    this.snack(err);
                    this.$router.push('/suppliers');
                });
        }
    };
</script>

<style scoped>

</style>