<template>
  <v-form class="d-flex flex-row align-center">
    <x-autocomplete
      v-model="selectedTargetLocation"
      :api-source="apiSource"
      clearable
      dense
      hide-details
      :placeholder="$t('tasks.stockPicking.chooseTargetLocation')"
      :solo="!invertedColors"
      :solo-inverted="invertedColors"
      @update:loading="val => loading = val"
      @keyup.enter.native="confirmTargetLocation"
    >
      <template #item="{ item: location }">
        <v-list-item-content>
          <v-list-item-title>{{ location.text }}</v-list-item-title>
          <v-list-item-subtitle> {{ location.is_mobile ? $t('stocks.locations.mobile') : $t('stocks.locations.nonMobile') }} </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </x-autocomplete>
    <v-btn
      outlined
      :disabled="selectedTargetLocation === null"
      :loading="loading"
      class="ml-2"
      type="submit"
      :color="invertedColors ? '' : 'accent'"
      @click.prevent="confirmTargetLocation"
    >
      {{ $t('base.save') }}
    </v-btn>
  </v-form>
</template>

<script>
    import {TaskSetTargetLocationMixin} from "@/app/mixins/TaskSetTargetLocationMixin";
    import {allowedLocationsInSubStock} from "@/app/stocks/locations/definitions/stockLocationDataSources";

    export default {
        name: "StockPickingTargetPicker",
        mixins: [TaskSetTargetLocationMixin],
        props: {
            taskInfo: {
                type: Object,
                default: () => ({})
            },
            api: {
                type: Object,
                default: () => ({})
            },
            invertedColors: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            loading: false,
            selectedTargetLocation: null
        }),
        computed: {
            API: function () {
                return this.api;
            },
            apiSource: function () {
                return allowedLocationsInSubStock(this.taskInfo.details.subordinate_stock.stock_id, this.taskInfo.details.subordinate_stock.id);
            }
        },
        watch: {
            'taskInfo.details.target_location': {
                handler: function (newValue) {
                    this.selectedTargetLocation = newValue.id;
                },
                immediate: true
            }
        },
        methods: {
            confirmTargetLocation: function () {
                if (!this.selectedTargetLocation) {
                    return;
                }
                this.reallySetTargetLocation(this.selectedTargetLocation);
            }
        }
    };
</script>

<style scoped>

</style>
