import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.parametersWithMultipleValues.includes(_vm.carrierServiceParameter.name))?_c(VCombobox,{attrs:{"label":_vm.$t('tasks.externalOrder.carrierServiceParameters.value'),"prepend-icon":"$attributeValue","rules":_vm.carrierServiceParameter.required ? [_vm.formRules.required] : [],"suffix":_vm.carrierServiceParameter.required ? '*' : '',"disabled":_vm.readOnly,"multiple":"","chips":"","small-chips":"","deletable-chips":"","clearable":""},model:{value:(_vm.multipleValue),callback:function ($$v) {_vm.multipleValue=$$v},expression:"multipleValue"}}):_c(VTextField,{attrs:{"label":_vm.$t('tasks.externalOrder.carrierServiceParameters.value'),"prepend-icon":"$attributeValue","rules":_vm.carrierServiceParameter.required ? [_vm.formRules.required] : [],"suffix":_vm.carrierServiceParameter.required ? '*' : '',"disabled":_vm.readOnly},model:{value:(
      /* eslint-disable-next-line vue/no-mutating-props */
      _vm.carrierServiceParameter.value
    ),callback:function ($$v) {_vm.$set(/* eslint-disable-next-line vue/no-mutating-props */
      _vm.carrierServiceParameter, "value", $$v)},expression:"\n      /* eslint-disable-next-line vue/no-mutating-props */\n      carrierServiceParameter.value\n    "}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }