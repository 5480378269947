import Vue from "vue";
import i18n from "@/service/lang/i18n";
import {
    addressInline as utilsAddressInline,
    locationLabel as utilsLocationLabel
} from "@/utils/string";
import moment from "moment";
import {Store} from "@/service/store/Store";
import {InstanceType} from "@/enum/instance_type";
import {DATE_DISPLAY_FORMAT, DATETIME_DISPLAY_FORMAT} from "@/utils/datetime";

/**
 * @param user {{
 *     first_name: string
 *     last_name: string
 * }}
 */
function fullName(user) {
    if (!user) {
        return i18n.t('tasks.nobody');
    } else if (user.first_name === undefined) {
        return user;
    } else {
        return user.first_name + ' ' + user.last_name;
    }
}

/**
 * @param fullName {{string}}
 * @return string
 */
function initials(fullName) {
    if (!fullName) {
        return '';
    } else {
        return fullName.split(' ').map(name => name.substring(0, 1)).join('. ').concat('.');
    }
}

/**
 * @param supplier {{
 *     name: string,
 *     ico: string
 * }}
 */
function supplierBrief(supplier) {
    return supplier === null ? ('(' + i18n.t('form.notFilled') + ')')
        : supplier.name + (supplier.ico !== null ? (' (' + supplier.ico + ')') : '');
}

/**
 * @param value {Boolean|null}
 * @returns {VueI18n.TranslateResult}
 */
function yesOrNo(value) {
    return value === null ? '' : value ? i18n.t('base.yes') : i18n.t('base.no');
}

/**
 * @param value {number}
 * @returns {string}
 */
const vatRate = value => value * 100 + '%';

/**
 * @param address {Object}
 * @returns {string}
 */
function addressInline(address) {
    return utilsAddressInline(address);
}

/**
 * @param location {{
 *     code: string,
 *     name: string
 * }}
 */
function locationLabel(location) {
    return utilsLocationLabel(location);
}

/**
 * @param state {int}
 * @returns {string}
 */
function taskState(state) {
    return i18n.t('tasks.state.' + state);
}

/**
 * @param priority {int}
 * @returns {string}
 */
function taskPriority(priority) {
    if (priority >= 1 && priority <= 3) {
        return i18n.t('tasks.priority.' + priority);
    } else {
        return i18n.t('tasks.priority.unknown');
    }
}

/**
 * @param deliveryType {deliveryType}
 * @returns {string}
 */
function deliveryType(deliveryType) {
    return i18n.t('tasks.deliveryAccept.deliveryType.' + deliveryType);
}

/**
 * @param strictMode
 * @returns {string}
 */
function strictMode(strictMode) {
    return i18n.t('tasks.strictMode.' + strictMode);
}

/**
 * @param type
 * @returns {boolean}
 */
function isBaseInstanceType(type) {
    return Object.values(InstanceType).includes(type);
}

/**
 * @param type
 * @returns {VueI18n.TranslateResult}
 */
function instanceType(type) {
    if (isBaseInstanceType(type)) {
        return i18n.t('products.instances.type.' + type);
    }
    return type;
}

/**
 * @param timeEntries {[{
 *    hours: string
 * }]}
 * @returns {string}
 */
function timeEntriesSum(timeEntries) {
    return timeEntries.reduce((acc, curr) => acc + parseFloat(curr.hours), 0) + 'h';
}

/**
 *
 * @param timeEntries
 * @return {string}
 */
function timeEntriesSumHumanize(timeEntries) {
    return elapsedTime(timeEntries.reduce((acc, curr) => acc + parseFloat(curr.hours), 0) * (1000 * 60 * 60));
}

/**
 * @param date {string} ISO-8601 datetime
 * @returns {string}
 */
function date(date) {
    return Vue.options.filters.moment(date, DATE_DISPLAY_FORMAT);
}

/**
 * @param date {string} ISO-8601 datetime
 * @returns {string}
 */
function dateTime(date) {
    return date && Vue.options.filters.moment(date, DATETIME_DISPLAY_FORMAT);
}

/**
 * @param date {string} ISO-8601 datetime
 * @returns {string}
 */
function momentAgo(date) {
    return date && Vue.options.filters.moment(date, "from");
}

/**
 * @param date {string} ISO-8601 datetime
 * @returns {string}
 */
function momentAgoWithDateTime(date) {
    return momentAgo(date) + ' (' + dateTime(date) + ')';
}

/**
 * @param millisDiff {number} Time difference in milliseconds
 * @return {string}
 */
function elapsedTime(millisDiff) {
    const duration = moment.duration(millisDiff);
    return ''
        + (duration.years() > 0 ? (duration.years() + 'y ') : '')
        + (duration.months() > 0 ? (duration.months() + 'm ') : '')
        + (duration.days() > 0 ? (duration.days() + 'd ') : '')
        + (('00' + duration.hours()).slice(-2) + ':')
        + (('00' + duration.minutes()).slice(-2) + ':')
        + (('00' + duration.seconds()).slice(-2));

}

/**
 * Performs language-sensitive formatting of number.
 * Result should be only displayed to user.
 * If you want to perform further transformations or computations on it, use {@link numberToFixed} instead.
 * @param number
 * @param fractionDigits
 * @returns {string}
 */
function numberToFixedLocalized(number, fractionDigits) {
    return new Intl.NumberFormat().format(Number(parseFloat(number).toFixed(fractionDigits)));
}

/**
 * @param product
 * @return {string}
 */
function productLabel(product) {
    // loading string from cache
    if (typeof product === 'string') {
        return product;
    }
    let productLabel;
    if (Store.getters['userConfig/productModels']) {
        productLabel = /* product.manufacturer.abbreviation + ' ' + #15454 */ product.model;
    } else {
        productLabel = product.name;
    }

    if (product.batch || product.serial_number) {
        productLabel += (' (' + (product.batch || product.serial_number) + ')');
    }
    return productLabel;
}

/**
 * @param instance
 * @return {string}
 */
function getInstanceType(instance) {
    if (typeof instance.type === 'object') {
        return instance.type.name;
    }
    return instance.type;
}

/**
 * @param instance
 * @returns {boolean}
 */
function hasCustomInstanceType(instance) {
    return getInstanceType(instance) !== InstanceType.NORMAL && !instance.serial_number && !instance.batch_code;
}

/**
 * @param instance
 * @param product
 * @return {string}
 */
function instanceLabel(instance, product = instance.product) {
    if (instance) {
        let instanceLabel = '';
        if (hasCustomInstanceType(instance)) {
            instanceLabel += `[${getInstanceType(instance)}] `;
        }
        if (Store.getters['userConfig/productModels']) {
            instanceLabel += product.manufacturer.abbreviation + ' ' + product.model;
        } else {
            instanceLabel += product.name;
        }
        if (getInstanceType(instance) !== InstanceType.NORMAL && (instance.batch_code || instance.serial_number)) {
            instanceLabel += ` (${instance.batch_code || instance.serial_number})`;
        }
        return instanceLabel;
    } else return '...';
}

/**
 * @param instance
 * @return {string}
 */
function instanceTypeLabel(instance) {
    if (!isBaseInstanceType(getInstanceType(instance))) {
        return getInstanceType(instance);
    }
    return i18n.t('products.instances.type.' + getInstanceType(instance)) + (
        getInstanceType(instance) === InstanceType.NORMAL
            ? ''
            : ': ' + (instance.batch_code || instance.serial_number)
    );
}

/**
 * @param instance
 * @return {string}
 */
function instanceTypeSpecialLabel(instance) {
    if (!isBaseInstanceType(getInstanceType(instance))) {
        return instanceTypeLabel(instance);
    } else {
        return '';
    }
}

/**
 * @param product
 * @return {string}
 */
function productMeasureLabel(product) {
    if (product && product.measure_unit) {
        return ' ' + product.measure_unit;
    } else {
        return ' ' + i18n.t('base.pcs');
    }
}

/**
 * @param subStock
 * @return {string}
 */
function subStockLabel(subStock) {
    // loading string from cache
    if (typeof subStock === 'string') {
        return subStock;
    }
    return subStock.name;
}

/**
 * @param subStock
 * @return {string}
 */
function carrierLabel(carrier) {
    // loading string from cache
    if (typeof carrier === 'string') {
        return carrier;
    }
    return carrier.name;
}

/**
 * @param manufacturer
 * @return {string}
 */
function manufacturerLabel(manufacturer) {
    // loading string from cache
    if (typeof manufacturer === 'string') {
        return manufacturer;
    }
    return manufacturer.abbreviation + ' (' + manufacturer.name + ')';
}

/**
 * @src https://stackoverflow.com/a/20732091
 * @param bytes
 * @return {string}
 */
function humanizeSize(bytes) {
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return bytes ? (bytes / Math.pow(1000, i)).toFixed(2) * 1 + ' ' + ['B', 'KB', 'MB', 'GB', 'TB'][i] : '0 B';
}

/**
 * @param string
 * @return {string}
 */
function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export default function () {
    Vue.filter('fullName', fullName);
    Vue.filter('initials', initials);
    Vue.filter('supplierBrief', supplierBrief);
    Vue.filter('yesOrNo', yesOrNo);
    Vue.filter('vatRate', vatRate);
    Vue.filter('addressInline', addressInline);
    Vue.filter('locationLabel', locationLabel);
    Vue.filter('taskState', taskState);
    Vue.filter('taskPriority', taskPriority);
    Vue.filter('deliveryType', deliveryType);
    Vue.filter('strictMode', strictMode);
    Vue.filter('instanceType', instanceType);
    Vue.filter('timeEntriesSum', timeEntriesSum);
    Vue.filter('timeEntriesSumHumanize', timeEntriesSumHumanize);
    Vue.filter('date', date);
    Vue.filter('dateTime', dateTime);
    Vue.filter('momentAgo', momentAgo);
    Vue.filter('momentAgoWithDateTime', momentAgoWithDateTime);
    Vue.filter('elapsedTime', elapsedTime);
    Vue.filter('numberToFixedLocalized', numberToFixedLocalized);
    Vue.filter('productLabel', productLabel);
    Vue.filter('instanceLabel', instanceLabel);
    Vue.filter('instanceTypeLabel', instanceTypeLabel);
    Vue.filter('instanceTypeSpecialLabel', instanceTypeSpecialLabel);
    Vue.filter('productMeasureLabel', productMeasureLabel);
    Vue.filter('subStockLabel', subStockLabel);
    Vue.filter('carrierLabel', carrierLabel);
    Vue.filter('manufacturerLabel', manufacturerLabel);
    Vue.filter('humanizeSize', humanizeSize);
    Vue.filter('capitalize', capitalize);
}

export {
    fullName,
    initials,
    addressInline,
    taskState,
    taskPriority,
    deliveryType,
    numberToFixedLocalized,
    instanceLabel,
    instanceTypeLabel,
    productLabel,
    productMeasureLabel,
    manufacturerLabel
};
