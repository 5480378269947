/**
 * Performs formatting of number to desired count of fraction digits.
 * Result can be used in further transformations and computations.
 * If you want to display it to user, use {@link numberToFixedLocalized} instead.
 * @param number
 * @param fractionDigits
 * @returns {number}
 */
function numberToFixed(number, fractionDigits) {
    return Number(parseFloat(number).toFixed(fractionDigits));
}

export { numberToFixed };