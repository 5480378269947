import Vue from "vue";

// Share Cache values across all components which use this mixin
const ProductCache = {};
let trigger = Vue.observable({count: 0});

const ReactiveProductCacheMixin = {
    computed: {
        loadingLabel: function () {
            return `<${this.$t('base.loading')}...`;
        },
        ProductCache: function () {
            // We need this to trigger an update in each component which might be using this Mixin simultaneously
            trigger.count;
            return ProductCache;
        }
    },
    methods: {
        cacheProduct: function (runningRequest, key, showLoadingInfo = true) {
            ProductCache[key] = showLoadingInfo ? this.loadingLabel : null;
            return runningRequest.then(response => {
                ProductCache[key] = response.data;
                trigger.count++;
                return response;
            });
        }
    }
};

export {ReactiveProductCacheMixin};