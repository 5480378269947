<template>
  <v-dialog
    v-model="show"
    width="500"
  >
    <v-card>
      <v-card-title>
        {{ $t('base.confirmLogout') }}
      </v-card-title>

      <v-card-text>
        {{ $t('base.logoutHint') }}
      </v-card-text>

      <v-card-actions>
        <v-btn
          text
          @click="cancelLogout"
        >
          {{ $t('base.cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn
          color="secondary"
          text
          @click="confirmLogout"
        >
          {{ $t('base.logout') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
    export default {
        name: "LogoutConfirmDialog",
        computed: {
            show: {
                get: function () {
                    return this.$store.getters['oauth/getConfirmLogout'];
                },
                set: function (value) {
                    this.$store.commit('oauth/setConfirmLogout', { confirmLogout: value });
                }
            }
        },
        methods: {
            cancelLogout: function() {
                this.$store.dispatch('oauth/cancelLogout');
            },
            confirmLogout: function() {
                this.$store.dispatch('oauth/confirmLogout');
            }
        }
    };
</script>

<style scoped>

</style>