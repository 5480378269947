<template>
  <v-tooltip bottom>
    <template #activator="{ on }">
      <router-link
        :to="taskLinks[task.type] + '/' + task.id"
        v-on="on"
      >
        #{{ task.id }}
      </router-link>
    </template>
    {{ $t(taskNames[task.type]) }}
  </v-tooltip>
</template>

<script>
    import {taskLinks, taskNames} from "@/enum/task_type";

    export default {
        name: "TaskWithTypeTooltip",
        props: {
            task: {
                type: Object,
                default: null
            }
        },
        data: () => ({
            taskNames: taskNames,
            taskLinks: taskLinks
        })
    };
</script>

<style scoped>

</style>
