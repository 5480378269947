export const buyerFabActions = (buyerId) => [
    {
        icon: '$buyerDiscount',
        label: 'buyers.wholesaleDiscounts.create.titleSimple',
        to: '/buyers/' + buyerId + '/discounts/create'
    },
    {
        icon: '$address',
        label: 'buyers.addresses.create.titleSimple',
        to: '/buyers/' + buyerId + '/address/create'
    }
];