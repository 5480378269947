<template>
  <div v-if="order.carrier_service_parameters && order.carrier_service_parameters.length > 0">
    <span class="text-caption">{{ $t('carriers.services.carrier_service_parameters') }}:</span>
    <ul>
      <li
        v-for="{name, value} of order.carrier_service_parameters"
        :key="name"
      >
        <span class="text-caption">{{ name }}</span>:
        <template v-if="parametersWithMultipleValues.includes(name)">
          <ul>
            <li
              v-for="val in tryParseJsonArray(value)"
              :key="val"
            >
              <template v-if="val.startsWith('http')">
                <a
                  :href="val"
                  target="_blank"
                >{{ val }}</a>
              </template>
              <template v-else>
                {{ val }}
              </template>
            </li>
          </ul>
        </template>
        <template v-else>
          <template v-if="value.startsWith('http')">
            <a
              :href="value"
              target="_blank"
            >{{ value }}</a>
          </template>
          <template v-else>
            {{ value }}
          </template>
        </template>
      </li>
    </ul>
  </div>
</template>

<script>
    import {carrierServiceParametersWithMultipleValues} from "@/app/carriers/definitions/carrierServiceParameters";

    export default {
        name: "ExternalOrderParametersList",
        props: {
            order: {
                type: Object,
                default: () => ({
                    carrier_service_parameters: null
                })
            }
        },
        data: () => ({
            parametersWithMultipleValues: carrierServiceParametersWithMultipleValues
        }),
        methods: {
            tryParseJsonArray(jsonString) {
                try {
                    return JSON.parse(jsonString);
                } catch {
                    // invalid JSON, cannot parse
                    return [];
                }
            }
        }
    };
</script>

<style scoped>

</style>
