import {WholesaleDiscountSaleType} from "@/enum/wholesale_discount_sale_type";

const Product = {
    getSingleImage: (product) => {
        if (product && product.images) {
            return product.images.find(image => image.is_main_photo) || product.images[0];
        } else {
            return undefined;
        }
    },
    computeDiscountedPrice: function (discount, valueIsInPercent, originalPrice) {
        if (!discount.product_id || !discount.value || discount.value === "NaN")
            return originalPrice;
        if (discount.sale_type === WholesaleDiscountSaleType.DISCOUNT_ABSOLUTE) {
            const result = originalPrice - discount.value;
            if (isNaN(result))
                return originalPrice;
            return result;
        }
        let discountPercent = discount.value;
        if (!valueIsInPercent)
            discountPercent /= 100;
        const result = originalPrice * (1 - discountPercent);
        if (isNaN(result))
            return originalPrice;
        return result;
    },
};

export {Product};
