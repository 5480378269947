<template>
  <div>
    <x-toolbar
      :title="pageTitle"
    />
    <v-card>
      <v-container fluid>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
        >
          <FormFields
            ref="fields"
            :form="form"
            :render="formRender"
            lang-path="products.batches."
          />
          <v-btn
            :text="!valid"
            :loading="loading"
            type="submit"
            color="accent"
            @click="submit()"
          >
            <v-icon
              class="mr-2"
            >
              $saveItem
            </v-icon>
            {{ $t('form.save') }}
          </v-btn>
        </v-form>
      </v-container>
    </v-card>
  </div>
</template>

<script>
    import FormFields from "@/app/components/form/FormFields.component";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {tabTitle} from "@/utils/string";
    import {ProductAPI} from "@/api/ProductAPI";
    import {setFormErrors} from "@/utils/form";
    import {ProductBatchForm, ProductBatchRender} from "@/app/products/batches/definitions/productBatch.form";
    import {FormFetchItemMixin} from "@/app/mixins/FormFetchItemMixin";
    import {ReactiveProductCacheMixin} from "@/app/mixins/ReactiveProductCacheMixin";

    export default {
        name: "ProductBatchCreateUpdate",
        components: {FormFields},
        mixins: [FormFetchItemMixin, RouteParamsMapperMixin, ReactiveProductCacheMixin],
        props: {
            isEdit: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            form: new ProductBatchForm,
            valid: true,
            loading: true
        }),
        computed: {
            formRender: function () {
                return new ProductBatchRender(this.isEdit);
            },
            pageTitle: function () {
                let title = this.$t(this.$route.meta.title);
                if (this.productName !== null) {
                    if (this.isEdit) {
                        title = this.$t('products.batches.update.title', [this.form.code, this.productName]);
                    } else {
                        title = this.$t('products.batches.create.title', [this.productName]);
                    }
                }
                if (!this._inactive) {
                    document.title = tabTitle(title);
                }
                return title;
            },
            productName: function () {
                return this.ProductCache[this.productId]?.name;
            },
        },
        createdOrActivated: function () {
            this.loading = true;
            this.cacheProduct(ProductAPI.get(this.productId), this.productId, false)
                .then(() => {
                    if (!this.isEdit) {
                        this.loading = false;
                    }
                })
                .catch(err => {
                    this.loading = false;
                    this.snack(err);
                    this.$router.push('/products');
                });
        },
        watch: {
            'form.code': function (newVal) {
                if(this.isEdit && newVal) {
                    this.loading = false;
                }
            }
        },
        methods: {
            formFetchItem: function () {
                return ProductAPI.getBatch(this.productId, this.batchCode);
            },
            submit: function () {
                if (!this.valid) {
                    this.$refs.form.validate();
                    return;
                }
                this.loading = true;
                if (this.isEdit) {
                    const batchCode = this.batchCode;
                    ProductAPI.updateBatch(this.productId, batchCode, this.form)
                        .then(() => {
                            this.advancedSnack({
                                text: 'products.batches.update.done',
                                params: [this.form.code, this.productName]
                            });
                            this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                            this.$router.push('/products/' + this.productId);
                        }).catch(setFormErrors.bind(this))
                        .finally(() => {
                            this.loading = false;
                        });
                } else {
                    ProductAPI.createBatch(this.productId, this.form)
                        .then(() => {
                            this.advancedSnack({
                                text: 'products.batches.create.done',
                                params: [this.form.code, this.productName]
                            });
                            this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                            this.$router.push('/products/' + this.productId);
                        }).catch(setFormErrors.bind(this))
                        .finally(() => {
                            this.loading = false;
                        });
                }
            },
        }
    };
</script>

<style scoped>

</style>
