const carrierServiceParameters = [
    'authentication',
    'authenticationNote',
    'branchId',
    'cod',
    'currency',
    'eshop',
    'invoiceLink',
    'eshopAttachments',
    'swap',
    'swapNote',
    'swapCount',
    'value',
    'variableSymbol',
    'weight'
];

const carrierServiceParametersWithMultipleValues = [
    'eshopAttachments',
];

export {carrierServiceParameters, carrierServiceParametersWithMultipleValues};