import {Roles} from "@/enum/roles";
import List from "@/app/buyers/BuyersList.view";
import CreateUpdate from "@/app/buyers/BuyerCreateUpdate.view";
import AddressCreateUpdate from "@/app/buyers/BuyerAddressCreateUpdate.view";
import BuyerShow from "@/app/buyers/BuyerShow.view";
import BuyerTypesList from "@/app/buyers/types/BuyerTypesList.view";
import BuyerTypeCreateUpdate from "@/app/buyers/types/BuyerTypeCreateUpdate.view";
import BuyerWholesaleDiscountCreateUpdate from "@/app/buyers/BuyerWholesaleDiscountCreateUpdate.view";

const BASE_URL = '/buyers';

const routes = {
    base: {
        path: BASE_URL,
        component: List,
        meta: {
            title: 'buyers.title',
            requireRole: [Roles.CHIEF],
            getParent: () => '/'
        }
    },

    show: {
        path: BASE_URL + '/:buyerId(\\d+)',
        component: BuyerShow,
        meta: {
            title: 'buyers.show.title',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.base
        }
    },
    create: {
        path: BASE_URL + '/create',
        component: CreateUpdate,
        props: {
            isEdit: false
        },
        meta: {
            title: 'buyers.create.title',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.base
        }
    },
    update: {
        path: BASE_URL + '/:buyerId(\\d+)/update',
        component: CreateUpdate,
        props: {
            isEdit: true
        },
        meta: {
            title: 'buyers.update.titleSimple',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.base
        }
    },

    types: {
        path: BASE_URL + '/types',
        component: BuyerTypesList,
        meta: {
            title: 'buyers.types.title',
            requiredRole: [Roles.CHIEF],
            getParent: () => routes.base
        }
    },
    typeCreate: {
        path: BASE_URL + '/types/create',
        component: BuyerTypeCreateUpdate,
        props: {
            isEdit: false
        },
        meta: {
            title: 'buyers.types.create.title',
            requiredRole: [Roles.CHIEF],
            getParent: () => routes.types
        }
    },
    typeUpdate: {
        path: BASE_URL + '/types/:typeId(\\d+)/update',
        component: BuyerTypeCreateUpdate,
        props: {
            isEdit: true
        },
        meta: {
            title: 'buyers.types.update.titleSimple',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.types
        }
    },

    addressCreate: {
        path: BASE_URL + '/:buyerId(\\d+)/address/create',
        component: AddressCreateUpdate,
        props: {
            isEdit: false
        },
        meta: {
            title: 'buyers.addresses.create.titleSimple',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.show
        }
    },
    addressUpdate: {
        path: BASE_URL + '/:buyerId(\\d+)/address/:addressId(\\d+)/update',
        component: AddressCreateUpdate,
        props: {
            isEdit: true
        },
        meta: {
            title: 'buyers.addresses.update.titleSimple',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.show
        }
    },

    discountCreate: {
        path: BASE_URL + '/:buyerId(\\d+)/discounts/create',
        component: BuyerWholesaleDiscountCreateUpdate,
        props: {
            isEdit: false
        },
        meta: {
            title: 'buyers.wholesaleDiscounts.create.titleSimple',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.show
        }
    },
    discountUpdate: {
        path: BASE_URL + '/:buyerId(\\d+)/discounts/:discountId(\\d+)/update',
        component: BuyerWholesaleDiscountCreateUpdate,
        props: {
            isEdit: true
        },
        meta: {
            title: 'buyers.wholesaleDiscounts.update.titleSimple',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.show
        }
    },

    fallback: {
        path: BASE_URL + '/*',
        beforeEnter: (to, from, next) => {
            // TODO snack message
            next(BASE_URL);
        }
    }
};

export default Object.values(routes);
