
const Channel = {
    channel: new BroadcastChannel('app-events'),

    registerEventHandler(eventName, handler) {
        this.channel.onmessage = (event) => {
            if (event.data.type === eventName) {
                handler(event);
            }
        };
    },

    publishEvent(eventName, payload = undefined) {
        this.channel.postMessage({ type: eventName, payload });
    }
};

export {Channel};