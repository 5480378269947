import {WholesaleDiscountType} from "@/enum/wholesale_discount_type";
import {WholesaleDiscountSaleType} from "@/enum/wholesale_discount_sale_type";

export default {
    title: "Vásárlók",
    link: "Vásárlók",

    name: "Név",
    ico: "ID",
    dic: "Adószám",
    phone: "Telefon",
    email: "Email",
    website: "Web",
    is_minor: "nem nagykorú",
    billing_address: "Számlázási cím",
    delivery_address: "Szállítási cím",
    address: "Szállítási cím",
    delivery_addresses: "Szállítási címek",
    type_id: "Típus",
    type: {
        id: "Típus",
        label: "Típus"
    },

    addresses: {
        public:"<public>",
        privacy: "magánszemély",
        default: "Alapértelmezett",
        markDefault: "Alapértelmezettként megjelölni",
        searchHint: "Keresi az utcát, a házszámot, a cím adatait, a várost, az irányítószámot és az országot.",

        create: {
            title: "Kézbesítési cím létrehozása '{0}' előfizető számára",
            titleSimple: "Új szállítási cím létrehozása",
            done: "Kézbesítési cím létrehozva."
        },
        update: {
            titleSimple: "Vásárlő szállítási címének szerkesztése",
            title: "A '{0}' előfizető szállítási címének szerkesztése",
            done: "Szállítási cím frissítve."
        },
        delete: {
            done: "Vásárlő kézbesítési címe törölve."
        },
        status: {
            404: "Az előfizető vagy a címe nem létezik!",
            409: "Az előfizető alapértelmezett szállítási címe nem lehet privát!"
        }
    },

    types: {
        link: "Vásárlói típusok",
        title: "Vásárlói típusok",
        titleSingle: "Vásárlói típusa",
        name: "Név",

        create: {
            label: "Új ügyféltípus létrehozása",
            title: "Új vevőtípus létrehozása",
            done: "'{0}' típus létrehozva"
        },
        update: {
            titleSimple: "Előfizetői típus szerkesztése",
            title: "Frissítés '{0}'",
            done: "'{0}' beállított"
        },
        delete: {
            done: "'{0}' törölve.",
            failed: "'Nem sikerült törölni {0}'. Kérjük, próbálja meg később újra."
        },

        status: {
            404: "A vásárló típusa nem található!",
            409: "Nem lehet törölni a vásárlótípust, mert néhány vásárló használja!"
        }
    },

    wholesaleDiscounts: {
        title: "Nagykereskedelmi kedvezmények",

        type: "Kedvezmény a",
        removeType: "Kedvezmény a '{0}'",
        manufacturer_id: "Gyártó",
        manufacturer: {
            id: "Gyártó"
        },
        product_id: "Raktárkészlet",
        product: {
            id: "Raktárkészlet"
        },
        sale_type: "A kedvezmény típusa",
        value: "Érték",
        original_sell_price: "Eredeti ár",
        discounted_sell_price: "Ár kedvezmény után",
        willBeAppliedOn: {
            start: "Ez a kedvezmény",
            products: "termékre vonatkozik."
        },

        typeEnum: {
            [WholesaleDiscountType.PRODUCT_SALE]: "Raktárkészlet",
            [WholesaleDiscountType.MANUFACTURER_SALE]: "Gyártó"
        },
        saleType: {
            [WholesaleDiscountSaleType.DISCOUNT_ABSOLUTE]: "Abszolút kedvezmény",
            [WholesaleDiscountSaleType.DISCOUNT_PERCENT]: "Százalékos kedvezmény"
        },

        create: {
            title: "Nagykereskedelmi kedvezmény létrehozása '{0}' vásárlók számára",
            titleSimple: "Nagykereskedelmi vásárlói kedvezmény létrehozása",
            done: "Nagykereskedelmi kedvezmény létre."
        },
        update: {
            title: "Nagykereskedelmi kedvezmény szerkesztése '{0}' vásárlók számára",
            titleSimple: "Az ügyfél nagykereskedelmi kedvezményének szerkesztése",
            done: "Nagykereskedelmi kedvezmény frissítve."
        },
        delete: {
            done: "A '{0}' ügyfél nagykereskedelmi kedvezménye törlésre került."
        },

        status: {
            404: "A vásárló vagy a nagykereskedelmi kedvezménye nem létezik!"
        }
    },

    list: {
        unableToLoad: "Az előfizetői listát nem sikerült betölteni. Kérjük, próbálja meg később újra."
    },
    show: {
        title: "Vásárlő",
    },
    create: {
        title: "Vásárlő létrehozása",
        label: "Új vásárlő létrehozása",
        done: "„{0}” ügyfél létrehozva",
        undo: {
            done: "A '{0}' létrehozása sikeresen visszaküldött!",
            failed: "Az előfizető létrehozását nem sikerült újra betölteni!"
        }
    },
    update: {
        titleSimple: "Vásárlő szerkesztése",
        title: "Szerkesztés {0}",
        done: "„{0}” ügyfél módosítva",
        undo: {
            done: "Módosítások '{0}' sikeresen visszaállítva!",
            failed: "Nem sikerült visszaadni az előfizető szerkesztését!"
        }
    },
    delete: {
        done: "Feliratkozó '{0}' törölve.",
        failed: "A '{0}' előfizető nem törölhető. Kérjük, próbálja meg később újra.",
        undo: {
            done: "A '{0}' törlése sikeresen visszaállítva!",
            failed: "Nem sikerült az előfizető törlésének visszaállítása!"
        },
        status: {
            404: "A megadott előfizető nem létezik!",
            409: "Az előfizetők nem törölhetők más objektumokkal való kapcsolatuk miatt!"
        }
    },
    status: {
        404: "A megadott előfizető nem létezik!",
        409: "Ezt az azonosító számot már egy másik előfizető használja!"
    }
};
