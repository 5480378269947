<template>
  <v-container fluid>
    <v-row>
      <v-divider
        class="my-3"
      />
    </v-row>
    <v-row>
      <slot
        name="title"
      >
        <div
          class="text-subtitle-1 ml-2"
        >
          {{ $t('tasks.externalOrder.carrierServiceParameters.title') }}:
        </div>
      </slot>
    </v-row>
    <v-row
      v-for="carrierServiceParam of carrierServiceParameters"
      :key="carrierServiceParam.id"
    >
      <v-layout
        wrap
        align-center
      >
        <v-flex
          xs12
          sm8
          md7
          lg4
          xl4
          class="px-1"
        >
          <v-text-field
            v-model="carrierServiceParam.name"
            :label="$t('tasks.externalOrder.carrierServiceParameters.name')"
            prepend-icon="$itemName"
            :disabled="true"
          />
        </v-flex>
        <v-flex
          xs12
          sm8
          md7
          lg4
          xl4
          class="px-1"
        >
          <CarrierServiceParameterValue
            :carrier-service-parameter="carrierServiceParam"
            :read-only="readOnly"
          />
        </v-flex>
      </v-layout>
    </v-row>
  </v-container>
</template>

<script>
    import formRules from "@/utils/formRules";
    import CarrierServiceParameterValue from "@/app/tasks/externalOrder/components/CarrierServiceParameterValue.component.vue";

    export default {
        name: "CarrierServiceParametersValues",
        components: {CarrierServiceParameterValue},
        props: {
            carrierServiceParameters: {
                type: Array,
                default: () => []
            },
            readOnly: {
                type: Boolean,
                default: false,
            },
        },
        data: () => ({
            formRules: formRules
        })
    };
</script>

<style scoped>

</style>
