<template>
  <v-card
    :key="locationId"
    class="card-list-item overflow-hidden"
    :class="{
      'mx-3 mt-3 pa-3 text--disabled': !active,
      'mx-1 mt-3 pa-2 elevation-8': active
    }"
    :color="active ? activeCardColor : ''"
    :outlined="items.length === 0"
  >
    <div
      v-if="locationId"
      class="pa-2"
    >
      <slot name="items-location-header">
        <TaskItemsLocationHeader :location-id="locationId" />
      </slot>
    </div>
    <div
      v-if="items.length > 0"
      class="d-flex"
      :class="{ 'flex-column': $vuetify.breakpoint.mdAndDown }"
    >
      <div
        :class="{ 'mb-2': $vuetify.breakpoint.mdAndDown }"
        class="mt-1"
        style="max-width: 100% /* required for ellipsis to work */"
      >
        <transition-group
          name="chip-list"
          mode="out-in"
        >
          <v-lazy
            v-for="(item) of items"
            :key="item.id"
            transition="slide-y-reverse-transition"
            min-height="32px"
            class="chip-list-item d-block"
          >
            <div>
              <slot
                name="card-item"
                :item="item"
              />
            </div>
          </v-lazy>
        </transition-group>
      </div>
    </div>
    <v-alert
      v-else-if="!itemsLoading && single && cardType === TaskItemsCardType.TO_MOVE && itemQuantityOnCard !== 0"
      type="error"
      text
    >
      {{ $t('tasks.itemsCard.stolenItems') }}
    </v-alert>
    <span
      v-else
      class="text--disabled"
    >
      <v-icon class="mb-1 mx-2">
        $nothing
      </v-icon>
      {{ $t('base.nothing') }}
    </span>
  </v-card>
</template>

<script>
    import {activeCardColor} from "@/styles/theme";
    import TaskItemsLocationHeader from "@/app/tasks/components/taskItemsCard/TaskItemsLocationHeader.component.vue";
    import {TaskItemsCardType} from "@/enum/task_items_card_type";
    import {EventsListenerMixin} from "@/app/mixins/EventsListenerMixin";

    export default {
        name: "TaskItemsLocation",
        components: {TaskItemsLocationHeader},
        mixins: [EventsListenerMixin],
        props: {
            // Is location the only one in current card type
            single: {
                type: Boolean,
                default: false
            },
            active: {
                type: Boolean,
                default: false
            },
            locationId: {
                type: Number,
                default: null
            },
            cardType: {
                type: Number,
                default: 0
            },
            items: {
                type: Array,
                default: () => [],
            },
            itemQuantityOnCard: {
                type: Number,
                default: 0
            },
            forceUpdate: {
                type: Number,
                default: 0
            }
        },
        data: () => ({
            activeCardColor: activeCardColor,
            TaskItemsCardType: TaskItemsCardType,
            itemsLoading: false
        }),
        computed: {
            events: function() {
                return {
                    'fetch-items': () => this.itemsLoading = true,
                    'fetch-items-done': () => this.itemsLoading = false,
                };
            }
        },
        watch: {
            forceUpdate: function () {
                this.$forceUpdate();
            }
        }
    };
</script>

<style scoped>

</style>
