import {BaseAPI} from "@/api/BaseAPI";
import {Sentry} from "@/service/Sentry";
import {merge} from "lodash";
import {APIHelper} from "@/service/APIHelper";

const SupplierAPI =  {

    API: BaseAPI,
    DOMAIN: 'suppliers',
    DOMAIN_TASKS: 'tasks',

    getAll() {
        return this.API.get(this.DOMAIN);
    },

    get(id) {
        return this.API.get([this.DOMAIN, id]);
    },

    getByIco(ico) {
        return this.API.get([this.DOMAIN, 'by-ico', ico]);
    },

    /**
     * @param params {{
     *     page: int
     *     itemsPerPage: int
     *     filter: {Object}
     *     sort: {
     *         key: 'ASC'|'DESC'
     *     }
     * }}
     */
    getAllTasks(supplierId, ...params) {
        return this.API.get(
            [this.DOMAIN, supplierId, this.DOMAIN_TASKS],
            this.DOMAIN,
            merge(...params)
        );
    },

    /**
     * @deprecated Too heavy API usage, use pagination when possible.
     */
    getAllTasksAllPages(supplierId, ...params) {
        return APIHelper.getAllPages(
            [this.DOMAIN, supplierId, this.DOMAIN_TASKS],
            this.DOMAIN,
            merge(...params)
        );
    },

    create(data) {
        return this.API.post(this.DOMAIN, data);
    },

    undoCreateFn(id, resolve, reject) {
        return () => {
            return this.API.delete([this.DOMAIN, id])
                .then(resolve)
                .catch(err => {
                    Sentry.captureException(err);
                    reject();
                });
        };
    },

    update(id, data) {
        return this.API.patch([this.DOMAIN, id], data);
    },

    delete(id) {
        return this.API.delete(
            [this.DOMAIN, id],
            [this.DOMAIN, 'delete']
        );
    }
};

export {SupplierAPI};
