<template>
  <div
    :id="`taskItemsCard-${cardType}`"
    class="taskItemsCard ma-1 pb-1 mt-3"
  >
    <BarcodePrintDialog
      :show.sync="printDialog"
      :print-type.sync="printType"
      :print-just-one-per-item.sync="printJustOnePerItem"
      :title="$t('products.instances.barcodes.batchPrint.label')"
      :confirm-text="$t('stocks.locations.printBarcodeMany')"
      :items="items"
      batch-print
      @print-barcode="batchPrint"
    />
    <v-card-title class="subtitle-1 pr-9 py-0 normalBreak">
      <TaskItemsCardIconNavigation :card-type="cardType" />
      <div class="d-flex flex-column">
        <span class="d-flex align-center">
          <slot
            name="cardTitle"
            :item-quantity="itemQuantityOnCard"
          >
            {{ $t('tasks.' + taskLangPath + cardType) }} ({{ itemQuantityOnCard }})
          </slot>
          <v-tooltip
            v-if="isAllowedToChangeTarget"
            bottom
          >
            <template #activator="{ on }">
              <v-btn
                icon
                small
                color="secondary"
                v-on="on"
                @click="changeTargetDialog = true"
              >
                <v-icon>
                  $itemChangeTargetLocation
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('tasks.itemsCard.changeTargetLocation') }}</span>
          </v-tooltip>
          <v-btn
            v-if="cardType === TaskItemsCardType.TO_MOVE"
            icon
            color="secondary"
            @click="debugItemQuantity++"
          >
            <v-icon>
              bug_report
            </v-icon>
          </v-btn>
        </span>
        <v-btn
          v-if="allowBatchPrint && items.length !== 0"
          :loading="printing"
          color="secondary"
          class="mb-2"
          @click="printDialog = true"
        >
          <v-icon
            small
            class="mr-2"
          >
            $printItem
          </v-icon>
          {{ $t('products.instances.barcodes.printMany') }}
        </v-btn>
      </div>
    </v-card-title>
    <Alert
      v-if="itemsNotReadyYet"
      show-alert
      :display-text="$t('tasks.itemsNotReadyYet')"
      class="mt-4"
    />
    <template v-else-if="isStockLocations">
      <TaskItemsLocations
        :active-location-id="activeLocationId"
        :active-card-is-on-this-side="activeCardIsOnThisSide"
        :card-type="cardType"
        :items="items"
        :item-is-on-location="itemIsOnLocation"
        :item-quantity-on-card="itemQuantityOnCard"
        :location-id-whitelist="onlyLocationIds"
        :location-id-blacklist="otherSideLocationIds"
        :sort-task-items-by-loaded-settings="sortTaskItemsByLoadedSettings"
        :task-info="taskInfo"
        :debug-item-quantity="debugItemQuantity"
        :item-quantity-on-location="itemQuantityOnLocation"
      >
        <template #items-location-header="{ locationId }">
          <slot
            name="items-location-header"
            :location-id="locationId"
          />
        </template>
        <template #card-item="{ item, locationId }">
          <TaskItemsCardItem
            :item="item"
            :active-location-id="activeLocationId"
            :active-location-side="activeLocationSide"
            :card-type="cardType"
            :item-quantity-on-location="itemQuantityOnLocation"
            :prices="prices"
            :location-id="locationId"
            :task-info="taskInfo"
            :inventory-empty="inventoryEmpty"
            @back="onClickBack"
            @remove="onClickRemove"
          />
        </template>
      </TaskItemsLocations>
    </template>
    <template v-else>
      <TaskItemsLocation
        single
        :active="assignedToCurrentUser && isInProgress && highlightSingleLocation"
        :card-type="cardType"
        :items="items"
        :item-quantity-on-card="itemQuantityOnCard"
      >
        <template #card-item="{ item }">
          <TaskItemsCardItem
            :item="item"
            :active-location-id="activeLocationId"
            :active-location-side="activeLocationSide"
            :card-type="cardType"
            :item-quantity-on-location="itemQuantityTotal"
            :prices="prices"
            :location-id="cardType === TaskItemsCardType.MOVED ? destinationLocationId : null"
            :task-info="taskInfo"
            :inventory-empty="inventoryEmpty"
            @back="onClickBack"
            @remove="onClickRemove"
          />
        </template>
      </TaskItemsLocation>
    </template>
    <v-btn
      v-if="showMoveAllButton"
      color="accent darken-2"
      class="ml-3 my-2 moveAllButton"
      outlined
      @click="$emit('moveAll')"
    >
      {{ $t('tasks.itemsCard.moveEverything') }}{{ LocationCache[activeLocationId] | locationLabel }}
    </v-btn>
    <v-dialog
      v-model="changeTargetDialog"
      width="450"
    >
      <v-card>
        <v-card-title>
          {{ $t('tasks.itemsCard.changeTargetLocation') }}
        </v-card-title>
        <v-card-text>
          <StockPickingTargetPicker
            v-if="canActuallyChangeTarget"
            :api="api"
            :task-info="taskInfo"
          />
          <v-alert
            v-else
            type="warning"
          >
            {{ $t('tasks.itemsCard.cannotChangeTargetLocation') }}
          </v-alert>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
    import {ReactiveLocationCacheMixin} from "@/app/mixins/ReactiveLocationCacheMixin";
    import {TaskStateMixin} from "@/app/mixins/TaskStateMixin";
    import StockPickingTargetPicker from "@/app/tasks/stockPicking/components/StockPickingTargetPicker.component";
    import {EventBus} from "@/service/EventBus";
    import {StockAPI} from "@/api/StockAPI";
    import {TaskItemsCardType} from "@/enum/task_items_card_type";
    import {activeCardColor} from "@/styles/theme";
    import TaskItemsCardItem from "@/app/tasks/components/taskItemsCard/TaskItemsCardItem.component";
    import {configurableTasks, taskTypes} from "@/enum/task_type";
    import {TaskShippingType} from "@/enum/task_shipping_type";
    import {EventsListenerMixin} from "@/app/mixins/EventsListenerMixin";
    import {TaskAssignMixin} from "@/app/mixins/TaskAssignMixin";
    import {TaskTypeMixin} from "@/app/mixins/TaskTypeMixin";
    import {ProductAPI} from "@/api/ProductAPI";
    import * as Export from "@/service/Export";
    import BarcodePrintDialog from "@/app/components/BarcodePrintDialog.component";
    import {PrintType} from "@/enum/print_type";
    import {TaskItemsStrictMode} from "@/enum/task_items_strict_mode";
    import {TaskStockTakingRestriction} from "@/enum/task_stock_taking_restriction";
    import Alert from "@/app/components/Alert.component.vue";
    import TaskItemsLocations from "@/app/tasks/components/taskItemsCard/TaskItemsLocations.component.vue";
    import TaskItemsLocation from "@/app/tasks/components/taskItemsCard/TaskItemsLocation.component.vue";
    import TaskItemsCardIconNavigation
        from "@/app/tasks/components/taskItemsCard/TaskItemsCardIconNavigation.component.vue";

    export default {
        name: "TaskItemsCard",
        components: {
            TaskItemsCardIconNavigation, Alert, TaskItemsLocations, TaskItemsLocation,
            TaskItemsCardItem, StockPickingTargetPicker, BarcodePrintDialog
        },
        mixins: [EventsListenerMixin, TaskTypeMixin, TaskAssignMixin, TaskStateMixin, ReactiveLocationCacheMixin],
        props: {
            api: {
                type: Object,
                default: () => ({})
            },
            activeLocationId: {
                type: Number,
                default: null
            },
            activeLocationSide: {
                type: String,
                default: null
            },
            cardType: {
                type: Number,
                default: 0
            },
            items: {
                type: Array,
                default: () => []
            },
            itemsUpdateEmit: {
                type: String,
                default: ''
            },
            prices: {
                type: Object,
                default: () => ({})
            },
            taskInfo: {
                type: Object,
                default: () => ({})
            },
            taskLangPath: {
                type: String,
                default: ''
            },
            isDestinationScanned: {
                type: Boolean,
                default: false
            },
            highlightSingleLocation: {
                type: Boolean,
                default: false
            },
            inventoryEmpty: {
                type: Boolean,
                default: true
            },
            allowBatchPrint: {
                type: Boolean,
                default: false
            },
            itemsNotReadyYet: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            TaskItemsCardType: TaskItemsCardType,
            changeTargetDialog: false,
            activeCardColor: activeCardColor,
            barcodesLoaded: false,
            sortTaskItemsByLoadedSettings: false,
            printDialog: false,
            printType: PrintType.ZPLX,
            printJustOnePerItem: true,
            printing: false,
            debugItemQuantity: 0
        }),
        computed: {
            events: function () {
                return {
                    'task-targetLocation-picked': () => this.changeTargetDialog = false,
                    'check-items-in-inventory': this.checkItemsInInventory
                };
            },
            stockId: function () {
                return this.taskInfo.details.subordinate_stock?.stock_id
                    || this.taskInfo.details.source_subordinate_stock?.stock_id
                    || this.taskInfo.details.stock?.id;
            },
            subStockId: function () {
                return this.taskInfo.details.subordinate_stock?.id || this.taskInfo.details.source_subordinate_stock?.id;
            },
            itemQuantityOnCard: function () {
                return this.items.reduce((acc, curr) => acc + this.itemQuantityTotal(curr), 0);
            },
            isAllowedToChangeTarget: function () {
                return this.isAnyOfTypes([taskTypes.STOCK_PICKING, taskTypes.STOCK_PICKING_SET])
                    && this.cardType === TaskItemsCardType.MOVED
                    && (this.isCreated || this.isInProgress);
            },
            canActuallyChangeTarget: function () {
                return this.isAllowedToChangeTarget
                    && (this.items.length === 0);
            },
            isStockLocations: function () {
                if ((this.isType(taskTypes.STOCK_LOADING) && this.cardType === TaskItemsCardType.TO_MOVE)
                    || (this.isType(taskTypes.STOCK_PICKING) && this.cardType === TaskItemsCardType.MOVED
                        && this.taskInfo.details.shipping_type === TaskShippingType.PERSONAL_COLLECTION)
                ) {
                    return false;
                } else {
                    return [TaskItemsCardType.TO_MOVE, TaskItemsCardType.MOVED,
                            TaskItemsCardType.PRESENT].includes(this.cardType);
                }
            },
            // TODO refactor
            otherSideLocationIds: function () {
                if (this.cardType === TaskItemsCardType.TO_MOVE) {
                    if (this.isDestinationScanned && this.activeLocationId) {
                        return [this.activeLocationId];
                    }
                    const id = this.taskInfo.details.target_location_id
                        || (this.taskInfo.details.destination_location && this.taskInfo.details.destination_location.id)
                        || false;
                    return id ? [id] : [];
                }
                if (this.cardType === TaskItemsCardType.MOVED) {
                    if (!this.isDestinationScanned
                        && this.activeLocationId
                        && !(this.isType(taskTypes.MOVE_PRODUCTS)
                            && this.taskInfo.details.transfer_mode === TaskItemsStrictMode.FREE
                        )) {
                        return [this.activeLocationId];
                    }
                    return [this.sourceLocationId] || [];
                }
                return [];
            },
            sourceLocationId: function () { // may be undefined
                return this.taskInfo.details.source_location?.id;
            },
            destinationLocationId: function () { // may be undefined
                return this.taskInfo.details.destination_location?.id
                    || this.taskInfo.details.target_location?.id
                    || this.taskInfo.details.target_location_id;
            },
            activeCardIsOnThisSide: function () {
                if (this.activeLocationSide !== null) {
                    return ((this.cardType === TaskItemsCardType.TO_MOVE && this.activeLocationSide === 'pick')
                        || (this.cardType === TaskItemsCardType.MOVED && this.activeLocationSide === 'put'));
                } else {
                    return true;
                }
            },
            onlyLocationIds: function () {
                if (this.cardType === TaskItemsCardType.TO_MOVE && this.sourceLocationId) {
                    return [this.sourceLocationId];
                }

                if (this.cardType === TaskItemsCardType.MOVED && this.destinationLocationId) {
                    return [this.destinationLocationId];
                }

                if (this.cardType === TaskItemsCardType.PRESENT) {
                    if (this.taskInfo.details.restrict_type === TaskStockTakingRestriction.STOCK_LOCATION
                        && this.taskInfo.details.restrict_references !== null) {
                        return this.taskInfo.details.restrict_references.map(loc => loc.id);
                    } else {
                        return [...new Set(this.items.map(item => item.stock_location_id))];
                    }
                }

                // TODO refactor
                if (this.cardType === TaskItemsCardType.MOVED) {
                    return [...new Set(this.items.flatMap(item =>
                        item.destination_locations !== undefined
                            ? item.destination_locations
                                .map(loc => this.isType(taskTypes.STOCK_LOADING) ? loc.location_id : loc.stock_location_id)
                            : [item.location_id || item.stock_location_id || item.destination_location?.stock_location_id]
                    ))]
                        .filter(id => !this.otherSideLocationIds.includes(id));
                }

                return null;
            },
            showMoveAllButton: function () {
                return this.cardType === TaskItemsCardType.IN_INVENTORY
                    && this.items.length !== 0
                    && this.isDestinationScanned
                    && this.items.filter(item => {
                        if (this.isAnyOfTypes([taskTypes.STOCK_PICKING, taskTypes.STOCK_PICKING_SET])) {
                            return true;
                        }
                        return !item.allowedLocationIds || item.allowedLocationIds.includes(this.activeLocationId);
                    }).length;
            }
        },
        createdOrActivated: function () {
            this.barcodesLoaded = false;
            this.loadTaskConfiguration();
        },
        methods: {
            // TODO move to parent, this makes the call three times in most tasks
            loadTaskConfiguration: function () {
                if (configurableTasks.includes(this.taskType)) {
                    StockAPI.getSubStockTaskSettingsConfig(this.stockId, this.subStockId)
                        .then(tasksConfig => {
                            const taskConfig = tasksConfig.data.find(setting => this.isType(setting.task_type));
                            if (taskConfig && taskConfig.sort_task_items_by_loaded) {
                                this.sortTaskItemsByLoadedSettings = true;
                            }
                        });
                }
            },
            handleTaskMovementError: function (err) {
                if (err.response && err.response.status === 409) {
                    EventBus.$emit('task-movement-conflict', err.response.data);
                } else {
                    this.snack(err);
                }
            },
            onClickBack: function (item, quantity, return_location, callback) {
                let promiseCallback = () => Promise.resolve();
                // TODO can be done locally and later synced with backend, however we would have to check if return is possible (user picked item from that source)
                if (this.cardType === TaskItemsCardType.IN_INVENTORY) {
                    promiseCallback = () => this.api.putToSource(this.taskInfo.taskId, item.id, return_location, quantity)
                        .then(() => {
                            EventBus.$emit('task-movement-conflict', null);
                            EventBus.$emit(this.itemsUpdateEmit, callback);
                        }).catch(this.handleTaskMovementError);
                } else if (this.cardType === TaskItemsCardType.MOVED) {
                    promiseCallback = () => this.api.pickUpFromDestination(this.taskInfo.taskId, item.id, return_location, quantity)
                        .then(() => {
                            EventBus.$emit('task-movement-conflict', null);
                            EventBus.$emit(this.itemsUpdateEmit, callback);
                        }).catch(this.handleTaskMovementError);
                }
                this.$emit('return-item', promiseCallback);
            },
            onClickRemove: function (item, locationId) {
                const index = this.items.findIndex(it => it.id === item.id);
                if (this.isType(taskTypes.STOCK_TAKING) && this.items[index].expected_amount > 0) {
                    /* eslint-disable-next-line vue/no-mutating-props */
                    this.items[index].real_amount = 0;
                    this.api.updateItem(this.taskInfo.taskId, item.id, 0);
                } else {
                    if (this.isType(taskTypes.STOCK_TAKING)) {
                        /* eslint-disable-next-line vue/no-mutating-props */
                        this.items.splice(index, 1);
                        this.api.deleteItem(this.taskInfo.taskId, item.id, locationId);
                    } else {
                        this.$emit('stock-loading-item-delete', item.id, locationId);
                    }
                }
            },
            itemQuantityTotal: function (item) {
                switch (this.cardType) {
                case TaskItemsCardType.TO_MOVE:
                    return item.quantity_to_move -
                        ((this.isAnyOfTypes([taskTypes.STOCK_PICKING, taskTypes.STOCK_PICKING_SET])
                            && this.taskInfo.details.shipping_type === TaskShippingType.PERSONAL_COLLECTION)
                            ? item.quantity_in_user_inventory
                            : (item.quantity_in_user_inventory + item.processed_quantity));
                case TaskItemsCardType.IN_INVENTORY:
                    return item.quantity_in_user_inventory;
                case TaskItemsCardType.MOVED:
                    return item.processed_quantity;
                case TaskItemsCardType.PRESENT:
                    return item.real_amount;
                case TaskItemsCardType.ASSIGNMENT:
                    if (this.isType(taskTypes.STOCK_TAKING)) {
                        return item.expected_amount;
                    }
                    if (this.isType(taskTypes.DELIVERY_ACCEPT)) {
                        return item.quantity;
                    }
                    return item.quantity_to_move;
                default:
                    return '';
                }
            },
            itemQuantityOnLocation: function (item, locationId) {
                if (this.cardType === TaskItemsCardType.TO_MOVE) {
                    if (item.locations !== undefined) {
                        const loc = item.locations.find(location => location.stock_location.id === locationId);
                        if (loc !== undefined) {
                            return Math.min(loc.quantity, item.quantity_to_move);
                        }
                    }
                } else {
                    if (item.destination_locations !== undefined) {
                        const itemAtLocation = item.destination_locations.find(el => this.isType(taskTypes.STOCK_LOADING)
                            ? el.location_id === locationId
                            : el.stock_location_id === locationId
                        );
                        if (itemAtLocation !== undefined) {
                            return this.isType(taskTypes.STOCK_LOADING) ? itemAtLocation.quantity : itemAtLocation.store_quantity;
                        } else {
                            return false;
                        }
                    } else if (item.destination_location !== undefined
                        && item.destination_location.stock_location_id === locationId
                    ) {
                        return item.destination_location.quantity;
                    } else {
                        return (item.location_id === locationId || item.stock_location_id === locationId)
                            && (item.quantity || item.real_amount);
                    }
                }
            },
            itemIsOnLocation: function (item, locationId) {
                return this.itemQuantityOnLocation(item, locationId) > 0
                    || (this.isType(taskTypes.STOCK_TAKING) && item.stock_location_id === locationId && item.expected_amount > 0);
            },
            batchPrint: function () {
                this.printDialog = false;
                this.printing = true;
                const promises = [];
                if (!this.barcodesLoaded) {
                    this.items.map(item => {
                        promises.push(
                            ProductAPI.getAllInstanceBarcodes(item.instance.product.id, item.instance.id).then(response => {
                                item.barcodes = response.data;
                            })
                        );
                    });
                }
                Promise.all(promises).then(() => {
                    this.barcodesLoaded = true;
                    const codes = this.items
                        .filter(item => item.barcodes.length)
                        .map(item => ({
                            code: item.barcodes[0].code,
                            amount: this.printJustOnePerItem ? 1 : item.quantity_to_move
                        }));
                    ProductAPI.printInstanceBarcodes(this.printType, codes)
                        .then(response => {
                            const fileExtension = this.printType;
                            Export.print(response.data.url,
                                         'task_' + this.taskInfo.details.id + '_codes' + fileExtension,
                                         this.printType);
                        })
                        .catch(this.snack)
                        .finally(() => {
                            this.printing = false;
                        });
                });
            },
            checkItemsInInventory: function (callback) {
                if (this.cardType === TaskItemsCardType.IN_INVENTORY && this.items && this.items.length !== 0) {
                    callback();
                }
            }
        }
    };
</script>

<style scoped lang="sass">
.normalBreak
  word-break: normal

.moveAllButton
  display: inline-block
  width: 95%
  white-space: normal
</style>
