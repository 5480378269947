<template>
  <div class="d-flex flex-row align-center">
    <div class="d-flex flex-wrap py-1 mr-5">
      <span
        class="mx-3"
        style="width: 20em"
      >
        <x-autocomplete
          v-model="
            // eslint-disable-next-line vue/no-mutating-props
            item.product_id
          "
          :api-source="productIdSource"
          disable-autoselect-first
          :label="$t(langPath + 'product')"
          prepend-icon="$itemsToMove"
          :rules="allowEmpty ? [] : [formRules.required]"
          :suffix="allowEmpty ? '' : '*'"
        >
          <template #item="{ item: autocompleteItem }">
            <TaskChooseItemsItemSelector :item="autocompleteItem" />
          </template>
        </x-autocomplete>
      </span>
      <span
        v-if="item.product_id"
        class="mx-3"
        style="width: 18em"
      >
        <x-autocomplete
          ref="input_specification"
          v-model="productSpecificationId"
          :api-source="specificationIdSource"
          :error-messages="item.errors"
          :label="specificationInputLabel"
          prepend-icon="$productInstance"
          :rules="specificationRules"
          :suffix="requireProductSpecification ? '*' : ''"
        >
          <template
            v-if="item.product_id"
            #prepend-item
          >
            <template
              v-if="!allowInstanceTypeSelect && allowCreateNewInstances"
            >
              <v-list>
                <v-list-item
                  :disabled="creatingNewInstance"
                  @click="createNewInstance()"
                >
                  <v-list-item-avatar>
                    <v-icon :disabled="creatingNewInstance">
                      $addProductInstance
                    </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('products.customInstances.create.label') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </template>
            <template v-if="allowInstanceTypeSelect">
              <v-switch
                v-model="chooseConcreteInstance"
                :label="$t(langPath + 'chooseConcreteInstance')"
                class="mx-3"
                @change="specificationDataSourceReload++"
              />
            </template>
          </template>
          <template #item="{ item: autocompleteItem }">
            <slot
              name="instanceItem"
              :item="autocompleteItem"
            >
              <TaskChooseItemsItemSelector :item="autocompleteItem" />
            </slot>
          </template>
          <template
            #selection="{ item: selectionItem }"
          >
            <slot
              name="instanceItem"
              :item="selectionItem"
            >
              {{ selectionItem.text }}
            </slot>
          </template>
        </x-autocomplete>
      </span>
      <span
        v-if="itemFields.includes('quantity')"
        class="mx-3"
        style="width: 8em"
      >
        <v-text-field
          v-model="
            // eslint-disable-next-line vue/no-mutating-props
            item.quantity
          "
          :label="$t(langPath + 'quantity')"
          prepend-icon="$quantity"
          :rules="fieldRules([formRules.positiveInteger, formRules.maxInteger(9999999)])"
          :suffix="fieldSuffix()"
          min="1"
          max="9999999"
          type="number"
        />
      </span>
      <span
        v-if="showSellPrice"
        class="mx-3"
        style="width: 16em"
      >
        <v-text-field
          v-model="
            // eslint-disable-next-line vue/no-mutating-props
            item.sell_price_per_unit
          "
          :label="$t(langPath + 'sellPricePerUnit')"
          :loading="loadingPrice > 0"
          prepend-icon="$productSuggestedSellPrice"
          :rules="fieldRules()"
          :suffix="fieldSuffix()"
          min="0"
          type="number"
        />
      </span>
      <span
        v-if="showBuyPrice"
        class="mx-3"
        style="width: 16em"
      >
        <v-text-field
          v-model="
            // eslint-disable-next-line vue/no-mutating-props
            item.buy_price_per_unit
          "
          :label="$t(langPath + 'buyPricePerUnit')"
          :loading="loadingPrice > 0"
          prepend-icon="$productSuggestedBuyPrice"
          type="number"
          step="0.001"
          min="0"
          :rules="buyPriceRules"
          @blur="
            // eslint-disable-next-line vue/no-mutating-props
            item.buy_price_per_unit = updateSuggestedBuyPrice(item.buy_price_per_unit)"
        />
      </span>
      <span
        v-if="showPriceVat"
        class="mx-3"
        style="width: 12em"
      >
        <v-select
          v-model="item.price_vat"
          :items="vatRateItems"
          :label="$t(langPath + 'priceVat')"
          :loading="loadingPrice > 0"
          prepend-icon="$productPriceVat"
          :rules="fieldRules()"
          :suffix="fieldSuffix()"
        />
      </span>
      <span
        v-if="showBuyPrice"
        class="mx-3"
        style="width: 12em"
      >
        <v-select
          v-model="item.buy_price_vat"
          :items="vatRateItems"
          :label="$t(langPath + 'priceVat')"
          :loading="loadingPrice > 0"
          prepend-icon="$productPriceVat"
        />
      </span>
    </div>
    <div>
      <v-tooltip
        bottom
      >
        <template #activator="{ on }">
          <v-btn
            v-show="item.product_id"
            :disabled="readonly"
            icon
            v-on="on"
            @click="$emit('removeItem')"
          >
            <v-icon>
              $removeItem
            </v-icon>
          </v-btn>
        </template>
        <span>
          {{ $t(langPath + 'removeItem') }}
        </span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
    import formRules from "@/utils/formRules";
    import {EventBus} from "@/service/EventBus";
    import {EventsListenerMixin} from "@/app/mixins/EventsListenerMixin";
    import {
        productInstances, productInstanceTypes,
        visibleProducts
    } from "@/app/tasks/definitions/taskItemsDataSources";
    import {vatRateItems} from "@/app/products/definitions/product.form";
    import {ProductAPI} from "@/api/ProductAPI";
    import TaskChooseItemsItemSelector from "@/app/tasks/components/TaskChooseItemsItemSelector.component.vue";
    import {numberToFixed} from "@/utils/number";

    // TODO limit quantity input by current product / instance amount in stock, use new Prop to turn this on
    export default {
        name: "TaskChooseItemsItem",
        components: {TaskChooseItemsItemSelector},
        mixins: [EventsListenerMixin],
        props: {
            item: {
                type: Object,
                default: () => ({})
            },
            // which properties user should supply for each item
            // some of: quantity, sell_price_per_unit, price_vat
            itemFields: {
                type: Array,
                default: () => ['quantity']
            },
            readonly: {
                type: Boolean,
                default: false
            },
            // whether user has to specify instance ID or instance type ID
            requireProductSpecification: {
                type: Boolean,
                default: false
            },
            productIdSource: {
                type: Object,
                default: visibleProducts
            },
            instanceIdSource: {
                type: Function,
                default: () => productInstances
            },
            instanceTypeIdSource: {
                type: Function,
                default: () => productInstanceTypes
            },
            allowEmpty: {
                type: Boolean,
                default: false
            },
            // whether user can choose just instance type instead of concrete instance
            allowInstanceTypeSelect: {
                type: Boolean,
                default: false
            },
            allowCreateNewInstances: {
                type: Boolean,
                default: false
            },
            // whether you can specify the buy price of item when creating stock loading
            showBuyPrice: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            chooseConcreteInstance: true,
            loadingPrice: 0,
            formRules: formRules,
            specificationDataSourceReload: 0,
            creatingNewInstance: false,
            langPath: 'tasks.chooseItems.',
            vatRateItems: vatRateItems,
        }),
        computed: {
            events: function () {
                return {
                    'create-instance-created': this.onCreateInstanceCreated,
                    'create-instance-cancelled': this.onCreateInstanceCancelled
                };
            },
            productSpecificationId: {
                get: function () {
                    if (this.chooseConcreteInstance) {
                        return this.item.product_instance_id;
                    } else {
                        return this.item.product_instance_type_id;
                    }
                },
                set: function (value) {
                    if (this.chooseConcreteInstance) {
                        this.item.product_instance_id = value;
                        this.item.product_instance_type_id = null;
                    } else {
                        this.item.product_instance_id = null;
                        this.item.product_instance_type_id = value;
                    }
                }
            },
            specificationIdSource: function () {
                this.specificationDataSourceReload;
                if (this.chooseConcreteInstance) {
                    return this.instanceIdSource(this.item.product_id);
                } else {
                    return this.instanceTypeIdSource(this.item.product_id);
                }
            },
            specificationInputLabel: function () {
                if (this.allowInstanceTypeSelect) {
                    return this.$t(this.langPath + 'productSpecification');
                }
                return this.$t(this.langPath + 'instance');
            },
            specificationRules: function () {
                if (this.requireProductSpecification) {
                    return (this.item.product_id !== null) ? [formRules.required] : [];
                } else {
                    return [];
                }
            },
            showSellPrice: function () {
                return this.itemFields.includes('sell_price_per_unit');
            },
            showPriceVat: function () {
                return this.itemFields.includes('price_vat');
            },
            buyPriceRules: function () {
                return isNaN(this.item.buy_price_per_unit) ? [] : [formRules.nonNegativeNumber];
            }
        },
        watch: {
            'item.product_id': function (val) {
                this.productSpecificationId = null;
                if (val && (this.showSellPrice || this.showPriceVat || this.showBuyPrice)) {
                    this.fetchProductSellPrice();
                }
            },
            'item.quantity': function () {
                this.$set(this.item, 'errors', []);
            }
        },
        methods: {
            fetchProductSellPrice: function () {
                this.loadingPrice++;
                return ProductAPI.get(this.item.product_id).then(response => {
                    if (response.data.id === this.item.product_id) {
                        if (this.showSellPrice || this.showPriceVat) {
                            // eslint-disable-next-line vue/no-mutating-props
                            this.item.sell_price_per_unit = response.data.suggested_sell_price_without_vat;
                            // eslint-disable-next-line vue/no-mutating-props
                            this.item.price_vat = +response.data.price_vat;
                        } else {
                            // eslint-disable-next-line vue/no-mutating-props
                            this.item.buy_price_per_unit = response.data.suggested_buy_price_without_vat;
                            // eslint-disable-next-line vue/no-mutating-props
                            this.item.buy_price_vat = +response.data.price_vat;
                        }
                    }
                }).finally(() => {
                    this.loadingPrice--;
                });
            },
            fieldRules: function (extraRules = []) {
                return this.item.product_id !== null ? [formRules.required, ...extraRules] : [];
            },
            fieldSuffix: function () {
                return this.item.product_id !== null ? '*' : '';
            },
            createNewInstance: function () {
                this.$refs.input_specification.$children[0].blur();
                this.creatingNewInstance = true;
                EventBus.$emit('create-instance', this.item.product_id);
            },
            onCreateInstanceCreated: function (instanceId) {
                this.specificationDataSourceReload++;
                // eslint-disable-next-line vue/no-mutating-props
                this.item.product_instance_id = Number.parseInt(instanceId, 10);
                this.creatingNewInstance = false;
            },
            onCreateInstanceCancelled: function () {
                this.creatingNewInstance = false;
            },
            updateSuggestedBuyPrice: function (price) {
                return numberToFixed(price, 3);
            },
        }
    };
</script>

<style scoped>

</style>
