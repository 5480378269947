import {DeliveryType, DeliveryNumber, InvoiceNumber, Note, SupplierId, ExternalOrderTaskId} from "@/app/tasks/definitions/taskCommon.render.form";
import {deliveryTypes} from "@/enum/delivery_type";
import {TableFilter} from "@/enum/table_filter";
import {TaskState} from "@/enum/task_state";
import {TaskPriority} from "@/enum/task_priority";
import {fullName} from "@/utils/filters";
import i18n from "@/service/lang/i18n";
import {TaskItemsStrictMode} from "@/enum/task_items_strict_mode";

function DeliveryAcceptForm() {
    return {
        delivery_type: deliveryTypes.DELIVERY,
        delivery_number: null,
        invoice_number: null,
        supplier_id: null,
        external_order_task_id: null,
        hours: null,
        note: null,
        attachments: []
    };
}

function DeliveryAcceptRender() {
    return {
        delivery_type: new DeliveryType,
        delivery_number: new DeliveryNumber,
        invoice_number: new InvoiceNumber,
        supplier_id: new SupplierId,
        external_order_task_id: new ExternalOrderTaskId,
        note: new Note
    };
}

const deliveryAcceptTasksTable = (users) => {
    return {
        'delivery_accept.id': {
            label: 'deliveryAccept.name',
            filterType: TableFilter.NUMBER
        },
        'delivery_accept.state': {
            label: 'state.name',
            filterType: TableFilter.SELECT_MULTIPLE,
            filterItems: Object.values(TaskState).map(state => ({
                text: i18n.t('tasks.state.' + state),
                value: state,
            })),
            itemLabel: item => item.delivery_accept ? i18n.t('tasks.state.' + item.delivery_accept.state) : '',
        },
        'delivery_accept.priority': {
            label: 'priority.name',
            filterType: TableFilter.SELECT_MULTIPLE,
            filterItems: Object.values(TaskPriority).map(priority => ({
                text: i18n.t('tasks.priority.' + priority),
                value: priority,
            })),
        },
        'delivery_accept.assigned_user.id': {
            label: 'assigned_user',
            filterType: TableFilter.SELECT_MULTIPLE,
            filterItems: users.map(user => ({
                text: user.first_name + ' ' + user.last_name,
                value: user.id
            })),
            itemLabel: item => item.delivery_accept?.assigned_user ? fullName(item.delivery_accept.assigned_user) : '-',
        },
        'delivery_accept.delivery_type': {
            label: 'delivery_type',
            filterType: TableFilter.SELECT_MULTIPLE,
            filterItems: Object.values(deliveryTypes).map(type => ({
                text: i18n.t('tasks.deliveryAccept.deliveryType.' + type),
                value: type
            })),
        },
        invoice_number: {
            filterType: TableFilter.TEXT,
        },
        delivery_number: {
            filterType: TableFilter.TEXT,
        },
        'external_order.id': {
            label: 'external_order_task_id',
            filterType: TableFilter.NUMBER
        },
        'stock_loading.id': {
            label: 'stockLoading.name',
            filterType: TableFilter.NUMBER
        },
        'stock_loading.state': {
            label: 'state.name',
            filterType: TableFilter.SELECT_MULTIPLE,
            filterItems: Object.values(TaskState).map(state => ({
                text: i18n.t('tasks.state.' + state),
                value: state,
            })),
            itemLabel: item => item.stock_loading ? i18n.t('tasks.state.' + item.stock_loading.state) : '',
        },
        'stock_loading.priority': {
            label: 'priority.name',
            filterType: TableFilter.SELECT_MULTIPLE,
            filterItems: Object.values(TaskPriority).map(priority => ({
                text: i18n.t('tasks.priority.' + priority),
                value: priority,
            })),
        },
        'stock_loading.assigned_user.id': {
            label: 'assigned_user',
            filterType: TableFilter.SELECT_MULTIPLE,
            filterItems: users.map(user => ({
                text: user.first_name + ' ' + user.last_name,
                value: user.id
            })),
            itemLabel: item => item.stock_loading?.assigned_user ? fullName(item.stock_loading.assigned_user) : '-',
        },
        'stock_loading.strict_mode': {
            label: 'strictMode.label',
            filterType: TableFilter.SELECT_MULTIPLE,
            filterItems: Object.values(TaskItemsStrictMode).map(type => ({
                text: i18n.t('tasks.strictMode.' + type),
                value: type
            })),
        },
    };
};

export {DeliveryAcceptForm, DeliveryAcceptRender, deliveryAcceptTasksTable};
