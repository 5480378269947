<template>
  <div>
    <x-toolbar
      :title="pageTitle"
    />
    <template v-if="details">
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header class="text-subtitle-1">
            <div>
              <v-btn
                fab
                outlined
                small
                type="submit"
                class="mr-2"
                :to="'/buyers/' + buyerId + '/update'"
              >
                <v-icon>
                  $updateItem
                </v-icon>
              </v-btn>
              {{ details.name }}
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card>
              <v-container fluid>
                <v-layout wrap>
                  <v-flex
                    sm6
                    xs12
                  >
                    <div v-if="details.ico">
                      <span class="text-caption">{{ $t('buyers.ico') + ': ' }}</span>
                      {{ details.ico }}
                    </div>
                    <div v-if="details.dic">
                      <span class="text-caption">{{ $t('buyers.dic') + ': ' }}</span>
                      {{ details.dic }}
                    </div>
                    <div v-if="details.phone">
                      <span class="text-caption">{{ $t('buyers.phone') + ': ' }}</span>
                      {{ details.phone }}
                    </div>
                    <div v-if="details.email">
                      <span class="text-caption">{{ $t('buyers.email') + ': ' }}</span>
                      {{ details.email }}
                    </div>
                    <div v-if="details.website">
                      <span class="text-caption">{{ $t('buyers.website') + ': ' }}</span>
                      {{ details.website }}
                    </div>
                    <div v-if="addressInline(details.billing_address) !== ''">
                      <span class="text-caption">{{ $t('buyers.billing_address') + ': ' }}</span>
                      {{ details.billing_address | addressInline }}
                    </div>
                  </v-flex>
                  <v-flex
                    sm6
                    xs12
                  >
                    <EntityHistory :changes="details" />
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-layout wrap>
        <v-flex
          xs12
          class="pa-1"
        >
          <v-expansion-panels
            :value="0"
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="text-subtitle-1">
                {{ $t('buyers.delivery_addresses') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <BuyerDeliveryAddressList />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-flex>
        <v-flex
          xs12
          class="pa-1"
        >
          <BuyerWholesaleDiscountsList
            :buyer-id="buyerId"
            :buyer-name="details.name"
          />
        </v-flex>
        <v-flex
          xs12
          class="pa-1"
        >
          <BuyerExternalOrdersList />
        </v-flex>
      </v-layout>
      <x-btn-fab-expander
        :actions="fabActions"
      />
    </template>
    <template v-else>
      <v-progress-linear
        indeterminate
      />
    </template>
  </div>
</template>

<script>
    import {BuyerAPI} from "@/api/BuyerAPI";
    import EntityHistory from "@/app/components/EntityHistory.component";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {tabTitle} from "@/utils/string";
    import {addressInline} from "@/utils/filters";
    import BuyerDeliveryAddressList from "@/app/buyers/components/BuyerDeliveryAddressList.component";
    import BuyerExternalOrdersList from "@/app/buyers/components/BuyerExternalOrdersList.component";
    import BuyerWholesaleDiscountsList from "@/app/buyers/components/BuyerWholesaleDiscountsList.component";
    import {buyerFabActions} from "@/app/buyers/definitions/buyerFabActions.definition";

    export default {
        name: "BuyerShow",
        components: {EntityHistory, BuyerDeliveryAddressList, BuyerWholesaleDiscountsList, BuyerExternalOrdersList},
        mixins: [RouteParamsMapperMixin],
        data: () => ({
            details: null,
            addressInline: addressInline
        }),
        computed: {
            pageTitle: function () {
                let title = this.$t(this.$route.meta.title);
                if (this.details !== null) {
                    title = this.details.name;
                }
                if (!this._inactive) {
                    document.title = tabTitle(title);
                }
                return title;
            },
            fabActions: function () {
                return buyerFabActions(this.buyerId);
            }
        },
        createdOrActivated: function () {
            BuyerAPI.get(this.buyerId)
                .then(response => {
                    this.details = response.data;
                }).catch(err => {
                    this.snack(err);
                    this.$router.push('/buyers');
                });
        }
    };
</script>

<style scoped>

</style>
