import {StockAPI} from "@/api/StockAPI";
import {locationLabel} from "@/utils/string";

export function locationsInStock(stockId, multiple = false) {
    return {
        apiDataSource: StockAPI.getAllLocations.bind(StockAPI, stockId),
        resultTransform: el => {
            el.value = el.id;
            el.text = locationLabel(el);
            return el;
        },
        multiple: multiple,
        disableAutoselectFirst: multiple
    };
}

export function allowedLocationsInSubStock(stockId, subStockId, multiple = false) {
    return {
        apiDataSource: StockAPI.getAllSubstockAvailableLocations.bind(StockAPI, stockId, subStockId),
        apiSort: {is_mobile: 'DESC'},
        resultTransform: el => {
            el.value = el.id;
            el.text = locationLabel(el);
            return el;
        },
        multiple: multiple,
        disableAutoselectFirst: multiple,
    };
}