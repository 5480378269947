import {WholesaleDiscountType} from "@/enum/wholesale_discount_type";
import {WholesaleDiscountSaleType} from "@/enum/wholesale_discount_sale_type";

export default {
    title: "Buyers",
    link: "Buyers",

    name: "Name",
    ico: "ID",
    dic: "VAT ID",
    phone: "Phone",
    email: "E-mail",
    website: "Web",
    is_minor: "is minor",
    billing_address: "Billing address",
    delivery_address: "Delivery address",
    address: "Delivery address",
    delivery_addresses: "Delivery addresses",
    type_id: "Type",
    type: {
        id: "Type",
        label: "Type"
    },

    addresses: {
        public: "<public>",
        privacy: "Private of user",
        default: "Default",
        markDefault: "Set as default",
        searchHint: "Searches through street, house number, address details, city, postal code and country",

        create: {
            title: "Create delivery address for '{0}",
            titleSimple: "Create new delivery address",
            done: "Delivery address created."
        },
        update: {
            titleSimple: "Update buyer's delivery address",
            title: "Update delivery address of '{0}'",
            done: "Delivery address updated."
        },
        delete: {
            done: "Buyer's delivery address deleted."
        },
        status: {
            404: "Buyer or address does not exist!",
            409: "Default delivery address cannot be private!"
        }
    },

    types: {
        link: "Buyer types",
        title: "Buyer types",
        titleSingle: "Buyer type",
        name: "Name",

        create: {
            label: "Create new buyer type",
            title: "New buyer type",
            done: "Type '{0}' created"
        },
        update: {
            titleSimple: "Update buyer type",
            title: "Update '{0}'",
            done: "'{0}' updated"
        },
        delete: {
            done: "'{0}' deleted.",
            failed: "'Unable to delete {0}'. Please try again later."
        },

        status: {
            404: "Buyer type not found!",
            409: "Unable to delete buyer type, because it is used by some buyers!"
        }
    },

    wholesaleDiscounts: {
        title: "Wholesale discounts",

        type: "Discount on",
        removeType: "Discount on '{0}'",
        manufacturer_id: "Manufacturer",
        manufacturer: {
            id: "Manufacturer"
        },
        product_id: "Product",
        product: {
            id: "Product"
        },
        sale_type: "Discount type",
        value: "Value",
        original_sell_price: "Original price",
        discounted_sell_price: "Discounted price",
        willBeAppliedOn: {
            start: "This discount will be applied on",
            products: "products."
        },

        typeEnum: {
            [WholesaleDiscountType.PRODUCT_SALE]: "Product",
            [WholesaleDiscountType.MANUFACTURER_SALE]: "Manufacturer"
        },
        saleType: {
            [WholesaleDiscountSaleType.DISCOUNT_ABSOLUTE]: "Fixed discount",
            [WholesaleDiscountSaleType.DISCOUNT_PERCENT]: "Percentage discount"
        },

        create: {
            title: "Create wholesale discount for buyer '{0}'",
            titleSimple: "Create wholesale discount of buyer",
            done: "Wholesale discount created."
        },
        update: {
            title: "Edit wholesale discount for buyer '{0}'",
            titleSimple: "Edit wholesale discount of buyer",
            done: "Wholesale discount updated."
        },
        delete: {
            done: "Wholesale discount of buyer '{0}' deleted."
        },

        status: {
            404: "Buyer or his wholesale discount does not exist!"
        }
    },

    list: {
        unableToLoad: "Unable to load buyer list. Please try again later."
    },
    show: {
        title: "Buyer",
    },
    create: {
        title: "Create buyer",
        label: "Create new buyer",
        done: "Buyer '{0}' created.",
        undo: {
            done: "Creation of '{0}' successfully canceled!",
            failed: "Failed to undo buyer creation!"
        }
    },
    update: {
        titleSimple: "Update buyer",
        title: "Update {0}",
        done: "Buyer '{0}' updated.",
        undo: {
            done: "Update of '{0}' successfully canceled!",
            failed: "Failed to undo buyer update!"
        }
    },
    delete: {
        done: "'{0}' deleted.",
        failed: "Unable to delete '{0}'. Please try again later.",
        undo: {
            done: "Deletion of '{0}' successfully canceled!",
            failed: "Failed to undo buyer delete!"
        },
        status: {
            404: "Buyer does not exist!",
            409: "Unable to delete buyer due to references to other objects!"
        }
    },
    status: {
        404: "Buyer does not exist!",
        409: "ID is already in use!"
    }
};
