<template>
  <div>
    <ConfirmDeleteDialog
      v-if="deletedDiscount !== null"
      :show.sync="confirmDialog"
      :text="discountTypeText(deletedDiscount)"
      @confirm-delete="reallyDeleteDiscount"
    />
    <v-expansion-panels
      :value="0"
    >
      <v-expansion-panel>
        <v-expansion-panel-header class="text-subtitle-1">
          {{ $t('buyers.wholesaleDiscounts.title') }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <x-data-table
            :headers="headers"
            :loading.sync="loading"
            :items.sync="items"
            :actions="actions"
            :api-data-source="apiDataSource"
            :api-data-source-all-pages="apiDataSourceAllPages"
            :show-search-bar="false"
            :reload="reload"
          >
            <template #item.product.id="{ item }">
              <div v-if="item.product_id">
                {{ ProductCache[item.product_id] | productLabel }}
              </div>
            </template>
            <template #item.manufacturer.id="{ item }">
              <div v-if="item.manufacturer_id">
                {{ ManufacturerCache[item.manufacturer_id] | manufacturerLabel }}
              </div>
            </template>
            <template #item.value="{ item }">
              <div v-if="item.sale_type === WholesaleDiscountSaleType.DISCOUNT_PERCENT">
                {{ item.value * 100 }} %
              </div>
              <div v-else>
                {{ item.value | numberToFixedLocalized(2) }}
              </div>
            </template>
            <template #item.original_sell_price="{ item }">
              <div v-if="item.product_id">
                <ProductSellPrice :product="ProductCache[item.product_id]" />
              </div>
            </template>
            <template #item.discounted_sell_price="{ item }">
              <div v-if="item.product_id">
                <ProductSellPrice
                  :product="ProductCache[item.product_id]"
                  :price-transform="Product.computeDiscountedPrice.bind(this, item, true)"
                />
              </div>
            </template>
            <template #footer>
              <TableAddItemButton
                :to="'/buyers/' + buyerId + '/discounts/create'"
                label="buyers.wholesaleDiscounts.create.titleSimple"
              />
            </template>
          </x-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
    import {BuyerAPI} from "@/api/BuyerAPI";
    import ConfirmDeleteDialog from "@/app/components/ConfirmDeleteDialog.component";
    import TableAddItemButton from "@/app/components/table/TableAddItemButton.component";
    import {createHeaders} from "@/utils/table";
    import {buyerDiscountTable} from "@/app/buyers/definitions/buyerDiscount.form";
    import {ReactiveProductCacheMixin} from "@/app/mixins/ReactiveProductCacheMixin";
    import {ProductAPI} from "@/api/ProductAPI";
    import {ReactiveManufacturerCacheMixin} from "@/app/mixins/ReactiveManufacturerCacheMixin";
    import {ManufacturerAPI} from "@/api/ManufacturerAPI";
    import {manufacturerLabel} from "@/utils/filters";
    import {WholesaleDiscountSaleType} from "@/enum/wholesale_discount_sale_type";
    import ProductSellPrice from "@/app/products/components/ProductSellPrice.vue";
    import {Product} from "@/utils/product";

    export default {
        name: "BuyerWholesaleDiscountsList",
        components: {ProductSellPrice, TableAddItemButton, ConfirmDeleteDialog},
        mixins: [ReactiveProductCacheMixin, ReactiveManufacturerCacheMixin],
        props: {
            buyerId: {
                type: Number,
                default: null
            },
            buyerName: {
                type: String,
                default: ''
            }
        },
        data: () => ({
            loading: false,
            headers: [],
            items: [],
            confirmDialog: false,
            deletedDiscount: null,
            reload: 0,
            WholesaleDiscountSaleType: WholesaleDiscountSaleType,
            Product: Product
        }),
        computed: {
            actions: function () {
                return [
                    {
                        routerLink: item => '/buyers/' + this.buyerId + '/discounts/' + item.whole_sale_discount_id + '/update',
                        icon: '$updateItem',
                        label: 'base.edit'
                    },
                    {
                        action: this.deleteItem,
                        icon: '$deleteTableItem',
                        label: 'base.delete'
                    }
                ];
            },
            apiDataSource: function () {
                return BuyerAPI.getAllDiscounts.bind(BuyerAPI, this.buyerId);
            },
            apiDataSourceAllPages: function () {
                return BuyerAPI.getAllDiscountsAllPages.bind(BuyerAPI, this.buyerId);
            }
        },
        watch: {
            items: function () {
                const uniqueProductIds = new Set(this.items.filter(item => item.product_id).map(item => item.product_id));
                uniqueProductIds.forEach(productId => this.cacheProduct(ProductAPI.get(productId), productId));

                const uniqueManufacturerIds = new Set(this.items.filter(item => item.manufacturer_id).map(item => item.manufacturer_id));
                uniqueManufacturerIds.forEach(manufacturerId => {
                    if (!this.ManufacturerCache[manufacturerId]) {
                        this.cacheManufacturer(ManufacturerAPI.get(manufacturerId), manufacturerId);
                    } // else fetching or already fetched
                });
            }
        },
        createdOrActivated: function () {
            this.headers = createHeaders(buyerDiscountTable, 'buyers.wholesaleDiscounts.');
        },
        methods: {
            discountTypeText: function (discount) {
                let label;
                if (discount.product_id) {
                    const discountProduct = this.ProductCache[discount.product_id];
                    label = discountProduct.name + ', ' + discountProduct.model;
                }
                if (discount.manufacturer_id) {
                    const discountManufacturer = this.ManufacturerCache[discount.manufacturer_id];
                    label = manufacturerLabel(discountManufacturer);
                }
                return this.$t('buyers.wholesaleDiscounts.removeType', [label]);
            },
            deleteItem: function (item) {
                this.deletedDiscount = item;
                this.confirmDialog = true;
            },
            reallyDeleteDiscount: function () {
                this.loading = true;
                this.confirmDialog = false;
                BuyerAPI.deleteDiscount(this.buyerId, this.deletedDiscount.whole_sale_discount_id)
                    .then(() => {
                        this.advancedSnack({
                            text: 'buyers.wholesaleDiscounts.delete.done',
                            params: [this.buyerName]
                        });
                    }).catch(this.snack)
                    .finally(() => {
                        this.reload++;
                        this.loading = false;
                    });
            }
        }
    };
</script>