export default {
    title: "Szállítók",
    link: "Szállítók",

    name: "Név",
    ico: "ICO",
    dic: "Adószám",
    phone: "Telefon",
    email: "Email",
    website: "Web",
    billing_address: "Számlázási cím",

    list: {
        unableToLoad: "A beszállítók listáját nem sikerült betölteni. Kérjük, próbálja meg később újra."
    },
    show: {
        title: "Szállító",
        tasks: "Szállítói szállítási és raktározási bizonylatok"
    },
    create: {
        label: "Új beszállító létrehozása",
        title: "Szállító létrehozása",
        done: "'{0}'szállító létrehozva.",
        undo: {
            done: "A '{0}' létrehozása sikeres!",
            failed: "A szállító létrehozása nem sikerült!"
        }
    },
    update: {
        titleSimple: "Szállító szerkesztése",
        title: "Szerkesztés {0}",
        done: "Szállító '{0}' módosítva",
        undo: {
            done: "A „{0}” szerkesztés sikeresen visszavonva!",
            failed: "A szállító szerkesztését nem sikerült visszavonni!"
        }
    },
    delete: {
        done: "A „{0}” szállító törölve.",
        failed: "A '{0}' szállító nem törölhető. Kérjük, próbálja meg később újra.",
        undo: {
            done: "A '{0}' törlése sikeres!",
            failed: "Nem sikerült a vendor törlését visszacsinálni!"
        },
        status: {
            404: "A megadott szállító nem létezik!",
            409: "Egy beszállító nem törölhető más objektumokkal való kapcsolatai miatt!"
        }
    },
    status: {
        404: "A megadott szállító nem létezik!",
        409: "Egy másik szállító már kitöltötte ezt az azonosítószámot!"
    }
};
