import { VBtn } from 'vuetify/lib/components/VBtn';
import { VForm } from 'vuetify/lib/components/VForm';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VForm,{staticClass:"d-flex flex-row align-center"},[_c('x-autocomplete',{attrs:{"api-source":_vm.apiSource,"clearable":"","dense":"","hide-details":"","placeholder":_vm.$t('tasks.stockPicking.chooseTargetLocation'),"solo":!_vm.invertedColors,"solo-inverted":_vm.invertedColors},on:{"update:loading":val => _vm.loading = val},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.confirmTargetLocation.apply(null, arguments)}},scopedSlots:_vm._u([{key:"item",fn:function({ item: location }){return [_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(location.text))]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(location.is_mobile ? _vm.$t('stocks.locations.mobile') : _vm.$t('stocks.locations.nonMobile'))+" ")])],1)]}}]),model:{value:(_vm.selectedTargetLocation),callback:function ($$v) {_vm.selectedTargetLocation=$$v},expression:"selectedTargetLocation"}}),_c(VBtn,{staticClass:"ml-2",attrs:{"outlined":"","disabled":_vm.selectedTargetLocation === null,"loading":_vm.loading,"type":"submit","color":_vm.invertedColors ? '' : 'accent'},on:{"click":function($event){$event.preventDefault();return _vm.confirmTargetLocation.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('base.save'))+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }