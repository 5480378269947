import {TableFilter} from "@/enum/table_filter";
import i18n from "@/service/lang/i18n";
import {WholesaleDiscountType} from "@/enum/wholesale_discount_type";
import {WholesaleDiscountSaleType} from "@/enum/wholesale_discount_sale_type";
import formRules from "@/utils/formRules";
import {visibleProducts} from "@/app/tasks/definitions/taskItemsDataSources";
import {manufacturers} from "@/app/manufacturers/definitions/manufacturerDataSources";
import {EventBus} from "@/service/EventBus";

function BuyerDiscountForm() {
    return {
        type: WholesaleDiscountType.PRODUCT_SALE,
        product_id: null,
        manufacturer_id: null,
        sale_type: WholesaleDiscountSaleType.DISCOUNT_PERCENT,
        value: null
    };
}

function BuyerDiscountRender() {
    return {
        type: {
            icon: '$buyerDiscountType',
            select: Object.values(WholesaleDiscountType).map(type => ({
                text: i18n.t('buyers.wholesaleDiscounts.typeEnum.' + type),
                value: type
            })),
            required: true
        },
        product_id: {
            icon: '$product',
            xAutocomplete: visibleProducts(false),
            show: true,
            required: true
        },
        manufacturer_id: {
            icon: '$manufacturer',
            xAutocomplete: manufacturers(false, true),
            show: false,
            required: false,
            createNew: {
                icon: '$addManufacturer',
                label: 'manufacturers.create.label',
                action: () => {
                    EventBus.$emit('create-manufacturer');
                }
            }
        },
        sale_type: {
            icon: '$buyerDiscountSaleType',
            select: Object.values(WholesaleDiscountSaleType).map(type => ({
                text: i18n.t('buyers.wholesaleDiscounts.saleType.' + type),
                value: type
            })),
            required: true
        },
        value: {
            icon: '$buyerDiscountValue',
            type: 'number',
            rules: [formRules.nonNegativeNumber],
            required: true,
            min: 0,
            step: "0.01"
        }
    };
}

const buyerDiscountTable = {
    type: {
        filterType: TableFilter.SELECT_MULTIPLE,
        filterItems: Object.values(WholesaleDiscountType).map(type => ({
            text: i18n.t('buyers.wholesaleDiscounts.typeEnum.' + type),
            value: type,
        })),
        itemLabel: item => i18n.t('buyers.wholesaleDiscounts.typeEnum.' + item.type)
    },
    'manufacturer.id': {
        filterType: TableFilter.SELECT_MULTIPLE,
        xAutocomplete: manufacturers(true, true),
        sortable: true,
        sortBy: 'manufacturer.name'
    },
    'product.id': {
        filterType: TableFilter.SELECT_MULTIPLE,
        xAutocomplete: visibleProducts(true),
        sortable: true,
        sortBy: 'product.name'
    },
    sale_type: {
        filterType: TableFilter.SELECT_MULTIPLE,
        filterItems: Object.values(WholesaleDiscountSaleType).map(type => ({
            text: i18n.t('buyers.wholesaleDiscounts.saleType.' + type),
            value: type
        })),
        itemLabel: item => i18n.t('buyers.wholesaleDiscounts.saleType.' + item.sale_type)
    },
    value: {
        filterType: TableFilter.NUMBER
    },
    original_sell_price: {
        sortable: false
    },
    discounted_sell_price: {
        sortable: false
    }
};

export {BuyerDiscountForm, BuyerDiscountRender, buyerDiscountTable};
